import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
  SET_WORKOUT,
  START_WORKOUT,
  startWorkout,
  stopWorkout,
} from "../Workout/Actions";
import { uuidv4 } from "../Player/Actions";

export const CONNECTING = "CONNECTING";
export const CONNECTION_ESTABLISHED = "CONNECTION_ESTABLISHED";
export const CONNECTION_ERROR = "CONNECTION_ERROR";
export const UPDATE_RETRY = "UPDATE_RETRY";

export const RECEIVE_PLAYER_DATA = "RECEIVE_PLAYER_DATA";
export const RESET_PLAYER_DATA = "RESET_PLAYER_DATA";
export const RECEIVE_PLAYER_LIST = "RECEIVE_PLAYER_LIST";
export const RECEIVE_GROUP_LIST = "RECEIVE_GROUP_LIST";
export const RECEIVE_STRAVA_AUTH_COMPLETE = "RECEIVE_STRAVA_AUTH_COMPLETE";
export const JOIN_GROUP = "JOIN_GROUP";

const host =
  process.env.NODE_ENV === "production"
    ? "wss://ride2000.com:8000"
    : "ws://localhost:8000";
let aliveInterval = undefined;
let client = undefined;

const connecting = () => ({
  type: CONNECTING,
});

const connectionEstablished = () => ({
  type: CONNECTION_ESTABLISHED,
});

const connectionError = () => ({
  type: CONNECTION_ERROR,
});

const receivePlayerData = (playerData) => ({
  type: RECEIVE_PLAYER_DATA,
  playerData,
});

const receivePlayerList = (playerList) => ({
  type: RECEIVE_PLAYER_LIST,
  playerList,
});

const resetPlayerData = () => ({
  type: RESET_PLAYER_DATA,
});

const receiveGroupList = (groupList) => ({
  type: RECEIVE_GROUP_LIST,
  groupList,
});

const receiveStravaAuthComplete = (token) => ({
  type: RECEIVE_STRAVA_AUTH_COMPLETE,
  token,
});

/*function handleRetry(dispatch) {
  retrySeconds--;
  console.log("retry..." + retrySeconds);
  if (retrySeconds === 0) {
    clearInterval(retryInterval);
    subscribeToPlayerMovement(dispatch);
  } else {
    dispatch(updateRetry(retrySeconds));
  }
}*/

export const sendPlayerData = () => (dispatch, getState) => {
  const state = getState();

  const data = createPlayerDataFromState(state);

  if (state.Socket.connected) {
    const str = JSON.stringify({ type: "PlayerData", data });
    client.send(str);
  } else {
    console.error("client not connected");
  }
};

export const sendJoin = () => (dispatch, getState) => {
  const state = getState();
  client.send(
    JSON.stringify({
      type: "Join",
      data: { uuid: state.Player.uuid, name: state.Player.name },
    })
  );
};

export const sendCreateGroup = (groupName) => (dispatch, getState) => {
  const state = getState();
  const groupUuid = uuidv4();
  client.send(
    JSON.stringify({
      type: "Group.Create",
      data: {
        uuid: groupUuid,
        name: groupName,
        player: { uuid: state.Player.uuid, name: state.Player.name },
      },
    })
  );
  dispatch(sendJoinGroup(groupUuid));
};

export const sendJoinGroup = (groupUuid) => (dispatch, getState) => {
  const state = getState();
  if (state.Socket.currentGroupUuid) {
    dispatch(sendLeaveGroup(state.Socket.currentGroupUuid));
  }
  client.send(
    JSON.stringify({
      type: "Group.Join",
      data: { uuid: state.Player.uuid, name: state.Player.name, groupUuid },
    })
  );
  dispatch({ type: JOIN_GROUP, groupUuid });
};

export const sendLeaveGroup = (groupUuid) => (dispatch, getState) => {
  const state = getState();
  client.send(
    JSON.stringify({
      type: "Group.Leave",
      data: { uuid: state.Player.uuid, name: state.Player.name, groupUuid },
    })
  );
  dispatch({ type: JOIN_GROUP, groupUuid: undefined });
  dispatch(resetPlayerData());
};

export const sendRemoveGroup = (groupUuid) => (dispatch, getState) => {
  const state = getState();
  client.send(
    JSON.stringify({
      type: "Group.Remove",
      data: { playerUuid: state.Player.uuid, uuid: groupUuid },
    })
  );
};

export const sendAlive = () => (dispatch, getState) => {
  const state = getState();
  client.send(
    JSON.stringify({ type: "Alive", data: { uuid: state.Player.uuid } })
  );
};

export const sendWorkout = () => (dispatch, getState) => {
  const state = getState();
  client.send(
    JSON.stringify({
      type: "Workout",
      data: {
        workout: {
          steps: state.Workout.workout,
          duration: state.Workout.workoutDuration,
        },
        playerUuid: state.Player.uuid,
      },
    })
  );
};

export const sendWorkoutStart = () => (dispatch, getState) => {
  const state = getState();
  client.send(
    JSON.stringify({
      type: "WorkoutStart",
      data: {
        playerUuid: state.Player.uuid,
      },
    })
  );
};

export const sendWorkoutStop = () => (dispatch, getState) => {
  const state = getState();
  client.send(
    JSON.stringify({
      type: "WorkoutStop",
      data: {
        playerUuid: state.Player.uuid,
      },
    })
  );
};

export const sendStravaExchangeToken = (code) => (dispatch, getState) => {
  const state = getState();
  client.send(
    JSON.stringify({
      type: "Strava.ExchangeToken",
      data: { uuid: state.Player.uuid, code },
    })
  );
};

export const uploadStravaActivity = (token, xml) => (dispatch, getState) => {
  const state = getState();
  client.send(
    JSON.stringify({
      type: "Strava.UploadActivity",
      data: {
        token: token,
        time: state.Bluetooth.timeElapsed,
        distance: state.Bluetooth.ridingDistance,
        elevationGain: state.Bluetooth.ridingElevation,
        tcx: xml,
      },
    })
  );
};

export const sendStravaActivity = (token) => (dispatch, getState) => {
  const state = getState();
  client.send(
    JSON.stringify({
      type: "Strava.CreateActivity",
      data: {
        token: token,
        time: state.Bluetooth.timeElapsed,
        distance: state.Bluetooth.ridingDistance,
        elevationGain: state.Bluetooth.ridingElevation,
      },
    })
  );
};

export const createPlayerDataFromState = (state) => {
  return {
    uuid: state.Player.uuid,
    name: state.Player.name,
    started: state.Player.started,
    weight: state.Player.weight,
    ridingDistance: state.Bluetooth.ridingDistance,
    ridingElevation: state.Bluetooth.ridingElevation,
    currentPower: state.Bluetooth.currentPower,
    currentSpeed: state.Bluetooth.currentSpeed,
    currentCadence: state.Bluetooth.currentCadence,
    currentHeartrate: state.Bluetooth.currentHeartrate,
    riderGraphicsVariant: state.Player.riderGraphicsVariant,
  };
};

export const subscribe = () => (dispatch) => {
  dispatch(connecting());
  client = new W3CWebSocket(host);
  console.log("subscribe to socket " + host);
  client.onopen = () => {
    console.log("socket connected");
    dispatch(connectionEstablished());
    dispatch(sendJoin());

    aliveInterval = setInterval(() => {
      dispatch(sendAlive());
    }, 10000);
  };
  client.onerror = () => {
    console.log("error");
    /*retrySeconds = 10;
        retryInterval = setInterval(() => handleRetry(dispatch),1000);
        dispatch(connectionError());*/
  };
  client.onmessage = (data) => {
    const jsondata = JSON.parse(data.data);
    if (jsondata.type === "PlayerData") {
      dispatch(receivePlayerData(jsondata.data));
    } else if (jsondata.type === "PlayerList") {
      dispatch(receivePlayerList(jsondata.data));
    } else if (jsondata.type === "Workout") {
      console.log(jsondata.data);
      dispatch({
        type: SET_WORKOUT,
        workout: jsondata.data.steps,
        duration: jsondata.data.duration,
      });
    } else if (jsondata.type === "WorkoutStart") {
      dispatch(startWorkout());
    } else if (jsondata.type === "WorkoutStop") {
      dispatch(stopWorkout());
    } else if (jsondata.type === "Group.List") {
      dispatch(receiveGroupList(jsondata.data));
    } else if (jsondata.type === "Strava.AuthComplete") {
      dispatch(receiveStravaAuthComplete(jsondata.data.access_token));
    } else {
      console.error("invalid data received");
      console.log(jsondata);
    }
  };
  client.onclose = () => {
    console.log("socket closed");
    // retrySeconds = 10;
    // retryInterval = setInterval(() => handleRetry(dispatch), 1000);
    dispatch(connectionError());
    clearInterval(aliveInterval);
  };
  // socket.on('playerMovement', timestamp => cb(null, timestamp));
  // socket.message('subscribeToPlayerMovement');
};
