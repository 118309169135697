import { Button, makeStyles, Modal } from "@material-ui/core";
import Theme from "../../../Core/Theme";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDistance, formatTime } from "../../../Core/utils";
import { FinishHistogram } from "../FinishHistogram";
import {
  sendStravaActivity,
  uploadStravaActivity,
} from "../../../Reducers/Socket/Actions";
import { createTcxFromPoints } from "../../../Core/TcxGenerator";

const useStyles = makeStyles({
  finishOverlay: {
    backgroundColor: "#FFFFFF",
    maxWidth: 600,
    maxHeight: 600,
    margin: "100px auto",
    padding: 24,
    fontFamily: Theme.font2P,
    "& p": {
      lineHeight: "24px",
    },
  },
  headline: {
    fontFamily: Theme.font2P,
    fontSize: 48,
    marginTop: 0,
    textAlign: "center",
    color: Theme.colors.violett,
  },
});

const FinishOverview = ({ onClose, compact }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    ridingDistance,
    ridingElevation,
    timeElapsed,
    kcal,
    powerHistory,
    heartrateHistory,
  } = useSelector((state) => state.Bluetooth);
  const { tcxPoints } = useSelector((state) => state.Track);

  const { stravaAuthComplete, stravaAccessToken } = useSelector(
    (state) => state.Socket
  );
  const windowRef = useRef();

  useEffect(() => {
    if (stravaAuthComplete && windowRef.current) {
      console.log("auth complete -> close window");
      windowRef.current.close();
      if (tcxPoints && tcxPoints.length > 0) {
        const xml = createTcxFromPoints(
          tcxPoints,
          powerHistory,
          heartrateHistory,
          ridingDistance,
          kcal
        );
        console.log("upload tcx to strava");
        // send data to strava
        dispatch(uploadStravaActivity(stravaAccessToken, xml));
      } else {
        // send the naked activity if no tcx data is available
        dispatch(sendStravaActivity(stravaAccessToken));
      }
    }
  }, [stravaAuthComplete]);

  const onSave = () => {
    // todo: save data
  };
  const onLeave = () => {
    onClose();
  };
  const onContinueRide = () => {
    onClose();
  };

  let maxPower = 0;
  let avgPower = 0;
  powerHistory.forEach((ph) => {
    avgPower += Number(ph.power);
    if (Number(ph.power) > maxPower) {
      maxPower = Number(ph.power);
    }
  });
  avgPower = Math.round(avgPower / powerHistory.length);

  return (
    <div className={classes.inner}>
      <h1
        className={classes.headline}
        style={compact ? { fontSize: 24 } : undefined}
      >
        THE MIGHTY STATS
      </h1>
      {!compact && (
        <p>
          It looks like you are not riding anymore. Do you want to save your
          ride and call it a day?
        </p>
      )}

      <div style={{ textAlign: "center" }}>
        <h3>{formatDistance(ridingDistance)} km</h3>
        <h3>{formatTime(timeElapsed)} min.</h3>
        <h3>{Math.round(kcal)} kcal</h3>
        <h3>max. {maxPower} W</h3>
        <h3>avg. {avgPower} W</h3>
      </div>
      <div>
        {/* todo: add full histogram -> create new component for this */}
        <FinishHistogram />
      </div>

      <div style={{ flex: 1, flexDirection: "row" }}>
        <Button
          onClick={() => {
            const redirectUrl =
              window.location.protocol +
              "//" +
              window.location.hostname +
              (window.location.port ? ":" + window.location.port : "");
            windowRef.current = window.open(
              "https://www.strava.com/oauth/authorize?redirect_uri=" +
                redirectUrl +
                "&client_id=61233&response_type=code&scope=activity:read_all,activity:write",
              "",
              "width=600,height=700,left=200,top=200"
            );
          }}
        >
          Strava
        </Button>
        <Button className={classes.buttonConnect} onClick={onContinueRide}>
          KEEP RIDING
        </Button>
      </div>
    </div>
  );
};

export const FinishOverlay = ({ open, onClose, compact }) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);

  return (
    <Modal open={open}>
      <div className={classes.finishOverlay}>
        {step === 1 && <FinishOverview onClose={onClose} compact={compact} />}
      </div>
    </Modal>
  );
};
