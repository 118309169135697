import {
  SET_FTP,
  SET_MAX_HR,
  SET_NAME,
  SET_RIDER_GRAPHICS,
  SET_UUID,
  SET_WEIGHT,
} from "./Actions";

const animals = [
  "Ant",
  "Antelope",
  "Axolotl",
  "Bat",
  "Bear",
  "Beaver",
  "Bonobo",
  "Fox",
  "Elephant",
  "Eagle",
  "Shark",
  "Dolphin",
];

export const initialState = {
  uuid: undefined,
  started: Math.round(Date.now() / 1000),
  name: "Random " + animals[Math.floor(Math.random() * (animals.length - 1))],
  weight: 75,
  ftp: 170,
  maxHr: 190,
  riderGraphicsVariant: 0,
};

export default function PlayerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_UUID: {
      return { ...state, uuid: action.uuid };
    }
    case SET_NAME:
      return { ...state, name: action.name };
    case SET_WEIGHT:
      return { ...state, weight: action.weight };
    case SET_FTP:
      return { ...state, ftp: action.ftp };
    case SET_MAX_HR:
      return { ...state, maxHr: action.maxHr };
    case SET_RIDER_GRAPHICS: {
      return { ...state, riderGraphicsVariant: action.variant };
    }

    default:
      return state;
  }
}
