import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Theme from "../../../Core/Theme";
import Icons, { Icon } from "../../../Core/Icons";
import { formatTime } from "../../../Core/utils";
import { getFtpZone12 } from "../../../Reducers/Bluetooth";

const useStyles = makeStyles({
  sensorsDisplay: {
    margin: 16,
    padding: 16,
    height: 100,
  },
  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  titleSmall: {
    fontFamily: Theme.font2P,
    margin: 0,
    fontSize: 16,
  },
  metricLarge: {
    fontFamily: Theme.font2P,
    margin: 0,
    fontSize: 48,
  },
  metricSmall: {
    fontFamily: Theme.font2P,
    margin: 0,
    fontSize: 24,
  },
  progress: {
    width: 320,
  },
  workoutStatus: {
    fontFamily: Theme.font2P,
    fontSize: 20,
  },
});

export const ProgressDot = ({
  color,
  width,
  height,
  noBorder = false,
  marginRight = 4,
}) => {
  return (
    <div
      style={{
        width: width * 0.8,
        height: height * 0.8,
        backgroundColor: color,
        borderLeftWidth: !noBorder ? width * 0.2 : 0,
        borderLeftColor: "#000",
        borderLeftStyle: "solid",
        borderBottomWidth: !noBorder ? width * 0.2 : 0,
        borderBottomColor: "#000",
        borderBottomStyle: "solid",
        marginRight,
      }}
    />
  );
};

const PowerBar = ({ currentPower, userFtp }) => {
  const classes = useStyles();

  const zone12 = getFtpZone12(currentPower, userFtp);

  const dots = [];
  for (let i = 1; i <= Math.min(zone12, 10); i++) {
    dots.push(Math.round(i / 2));
  }

  return (
    <div style={{ position: "relative" }}>
      <img src={Icons.progressBar} className={classes.progress} alt={""} />
      <div
        style={{
          position: "absolute",
          top: 12,
          left: 14,
          display: "flex",
          width: "100%",
        }}
      >
        {dots.map((dot, i) => (
          <ProgressDot
            key={"dot-" + i}
            color={Theme.zoneColors[dot]}
            width={24}
            height={24}
          />
        ))}
      </div>
      {zone12 === 11 && (
        <div style={{ position: "absolute", top: -10, right: 10 }}>
          <Fire />
        </div>
      )}
    </div>
  );
};

const Fire = () => {
  const [frame, setFrame] = useState(0);
  const ref = useRef();
  const refX = useRef();
  const refY = useRef();

  useEffect(() => {
    let i = 0;
    clearInterval(ref.current);
    ref.current = setInterval(() => {
      setFrame(i++);
      refY.current = Math.floor(i / 10);
      refX.current = i - Math.floor(i / 10) * 10;
      if (i === 60) {
        i = 0;
      }
    }, 50);
  }, []);

  return (
    <div
      style={{
        transform: "scale(4)",
        background: 'url("/img/Small_Fireball_10x26.png")',
        backgroundPositionX: refX.current * 10,
        backgroundPositionY: refY.current * 26,
        width: 10,
        height: 26,
      }}
    />
  );
};

const Heartrate = ({ heartrate }) => {
  const ref = useRef();
  const [size, setSize] = useState(42);

  useEffect(() => {
    clearInterval(ref.current);
    console.log("heartrate " + heartrate);
    if (heartrate && heartrate > 0) {
      const interval = 1000 / (heartrate / 60) / 2;
      console.log("set interval to " + interval);
      let i = 0;
      ref.current = setInterval(() => {
        if (i === 0) {
          setSize(44);
          i = 1;
        } else {
          i = 0;
          setSize(42);
        }
      }, interval);
    }
  }, [heartrate]);

  return (
    <div style={{ width: 48, display: "inline-block" }}>
      <Icon src={Icons.heart} size={size} />
    </div>
  );
};

export const SensorValuesDisplay = () => {
  const classes = useStyles();
  const { currentPower, currentCadence, currentHeartrate } = useSelector(
    (state) => state.Bluetooth
  );
  const { activeFtp, remainingTimeInStep, workoutStarted } = useSelector(
    (state) => state.Workout
  );
  const { ftp } = useSelector((state) => state.Player);

  let powerColor = Theme.colors.black;
  if (currentPower >= 250) {
    powerColor = Theme.colors.fireRed;
  }

  const targetPower = Math.round(activeFtp * ftp);

  const state =
    currentPower < targetPower * 0.9
      ? "UP"
      : currentPower > targetPower * 1.1
      ? "DOWN"
      : "OK";

  // <div>
  //   <p className={classes.timeLabel}>{formatTime(workoutTime)}</p>
  //   <p className={classes.timeLabel}>{formatTime(workoutDuration)}</p>
  // </div>
  // <div>
  //   <p className={classes.powerLabel}>{targetPower}W</p>
  // </div>

  const remainingTime = formatTime(remainingTimeInStep);

  const iconSize = 42;

  return (
    <div className={classes.sensorsDisplay}>
      <div className={classes.row}>
        <Icon src={Icons.watt} size={iconSize} />
        <h2
          className={classes.metricLarge}
          style={{ color: powerColor, marginLeft: 16, marginRight: 16 }}
        >
          {currentPower}
          <span style={{ fontSize: 24 }}>W</span>
        </h2>
        {workoutStarted && (
          <div>
            {state === "UP" && <Icon src={Icons.redArrow} size={iconSize} />}
            {state === "DOWN" && (
              <Icon src={Icons.greenArrow} size={iconSize} />
            )}
            {state === "OK" && <Icon src={Icons.check} size={iconSize} />}
          </div>
        )}
      </div>
      <div className={classes.row} style={{ marginTop: 16 }}>
        <div style={{ marginRight: 8 }}>
          <h2 className={classes.metricLarge}>
            <Icon src={Icons.cadence} size={iconSize} />
            {currentCadence}
          </h2>
        </div>
        <div>
          <div className={classes.row}>
            <div>
              <PowerBar currentPower={currentPower} userFtp={ftp} />
              {workoutStarted && (
                <h2 className={classes.workoutStatus}>
                  {targetPower}W for {remainingTime}
                </h2>
              )}
            </div>
          </div>
        </div>
        <div style={{ marginLeft: 8 }}>
          <h2 className={classes.metricLarge}>
            {currentHeartrate}
            <Heartrate heartrate={currentHeartrate} />
          </h2>
        </div>
      </div>
    </div>
  );
};
