import { useSelector } from "react-redux";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { formatDistance, formatTime } from "../../../Core/utils";
import Theme from "../../../Core/Theme";
import Icons, { Icon } from "../../../Core/Icons";
import { getFtpZone12 } from "../../../Reducers/Bluetooth";
import { ProgressDot } from "../SensorValuesDisplay";

const useStyles = makeStyles({
  metricsDisplay: {
    margin: 16,
    padding: 16,
    height: 100,
    width: 280,
  },
  metricsDisplayTransparent: {
    margin: 0,
    padding: 16,
    opacity: 0.8,
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginBottom: 8,
  },
  col: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginBottom: 8,
  },
  title: {
    margin: 0,
    textAlign: "left",
    width: 150,
    marginBottom: 4,
  },
  metricLarge: {
    fontFamily: Theme.font2P,
    margin: 0,
    fontSize: 28,
  },
  unitLarge: {
    fontSize: 18,
    marginLeft: 8,
  },
  metricSmall: {
    fontFamily: Theme.font2P,
    margin: 0,
    fontSize: 24,
  },
  metricSmallLight: {
    fontFamily: Theme.font2P,
    margin: 0,
    fontSize: 18,
    color: "#fff",
    textShadow: Theme.textShadow,
  },
  unitSmall: {
    fontSize: 16,
  },
  unitSmallLight: {
    fontSize: 12,
    color: "#fff",
    textShadow: Theme.textShadow,
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: 16,
  },
});

const PowerBar = ({ currentPower, userFtp, style, small }) => {
  const zone12 = getFtpZone12(currentPower, userFtp);
  const dot = Math.round(Math.min(zone12, 10) / 2);

  const dots = [];
  for (let i = 1; i <= Math.min(zone12, 10); i++) {
    dots.push(Math.round(i / 2));
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 6,
        padding: 2,
        marginLeft: 8,
        width: small ? 50 : 84,
        height: small ? 6 : 14,
        border: "2px solid",
        borderColor: Theme.colors.grey,
        borderRadius: 4,
        ...style,
      }}
    >
      {dots.map((dot, i) => (
        <ProgressDot
          key={"dot-" + i}
          color={Theme.zoneColors[dot]}
          width={small ? 4 : 8}
          height={small ? 8 : 16}
          noBorder={true}
          marginRight={2}
        />
      ))}
    </div>
  );
};

export const MetricsDisplay = ({ compact, overlay }) => {
  const classes = useStyles();
  const {
    currentSpeed,
    ridingDistance,
    ridingElevation,
    timeElapsed,
    kcal,
    currentPower,
    currentCadence,
    currentHeartrate,
  } = useSelector((state) => state.Bluetooth);
  const { ftp } = useSelector((state) => state.Player);
  const { inclinePercent, distance: trackDistance, trackLoaded } = useSelector(
    (state) => state.Track
  );

  const time = formatTime(timeElapsed);
  const distance = formatDistance(ridingDistance);

  const darkMode = overlay;

  const metricSize = darkMode
    ? classes.metricSmallLight
    : compact
    ? classes.metricSmall
    : classes.metricLarge;
  const unitSize = darkMode
    ? classes.unitSmallLight
    : compact
    ? classes.unitSmall
    : classes.unitLarge;

  return darkMode ? (
    <div className={classes.metricsDisplayTransparent}>
      <div className={classes.row} style={{ marginRight: 16 }}>
        <Icon src={Icons.watt} style={{ marginRight: 4 }} size={24} />
        <div>
          <h2 className={metricSize}>
            {Math.round(currentPower)}
            <span className={unitSize}>W</span>
          </h2>
          <PowerBar
            currentPower={currentPower}
            userFtp={ftp}
            style={{ marginTop: 0, marginRight: 8 }}
            small={darkMode}
          />
        </div>
      </div>
      <div className={classes.row} style={{ marginRight: 16 }}>
        <Icon src={Icons.cadence} style={{ marginRight: 16 }} size={24} />
        <h2 className={metricSize}>
          {Math.round(currentCadence)}
          <span className={unitSize}>U/m</span>
        </h2>
      </div>
      <div className={classes.row} style={{ marginRight: 16 }}>
        <Icon src={Icons.heart} style={{ marginRight: 8 }} size={24} />
        <h2 className={metricSize}>
          {Math.round(currentHeartrate)}
          <span className={unitSize}>Hz</span>
        </h2>
      </div>
      <div className={classes.row} style={{ marginRight: 16 }}>
        <Icon src={Icons.speed} style={{ marginRight: 4 }} size={24} />
        <h2 className={metricSize}>
          {Math.round(currentSpeed * 3.6 * 10) / 10}
          <span className={unitSize}>km/h</span>
        </h2>
      </div>
      <div className={classes.row} style={{ marginRight: 16 }}>
        <Icon src={Icons.flag} style={{ marginRight: 4 }} size={24} />
        <div>
          <h2 className={metricSize}>
            {distance}
            <span className={unitSize}>km</span>
          </h2>
          {trackLoaded && (
            <h2 className={metricSize} style={{ fontSize: 16, marginTop: 8 }}>
              {Math.round(trackDistance / 100) / 10}
              <span className={unitSize}>km</span>
            </h2>
          )}
        </div>
      </div>
      <div className={classes.row} style={{ marginRight: 16 }}>
        <Icon src={Icons.mountain} style={{ marginRight: 4 }} size={24} />
        <div>
          <h2 className={metricSize}>
            {Math.round(ridingElevation)}
            <span className={unitSize}>m</span>
          </h2>
          {trackLoaded && (
            <h2 className={metricSize} style={{ fontSize: 16, marginTop: 8 }}>
              {inclinePercent > 0 ? "+" : ""}
              {Math.round(inclinePercent)}
              <span className={unitSize}>%</span>
            </h2>
          )}
        </div>
      </div>
      <div className={classes.row} style={{ marginRight: 16 }}>
        <Icon src={Icons.time} style={{ marginRight: 4 }} size={24} />
        <h2 className={metricSize}>
          {time}
          {!compact && <span className={unitSize}>min.</span>}
        </h2>
      </div>
      <div className={classes.row}>
        <Icon src={Icons.calories} style={{ marginRight: 4 }} size={24} />
        <h2 className={metricSize}>
          {Math.round(kcal)}
          <span className={unitSize}>kcal</span>
        </h2>
      </div>
    </div>
  ) : (
    <div
      className={
        darkMode ? classes.metricsDisplayTransparent : classes.metricsDisplay
      }
      style={compact ? { padding: 0, height: 250 } : null}
    >
      {compact && (
        <>
          <div className={classes.row}>
            <h2 className={metricSize}>
              <Icon src={Icons.watt} style={{ marginRight: 16 }} size={24} />
              {Math.round(currentPower)}
              <span className={unitSize}>W</span>
            </h2>
            <PowerBar currentPower={currentPower} userFtp={ftp} />
          </div>
          <div className={classes.row}>
            <h2 className={metricSize}>
              <Icon src={Icons.cadence} style={{ marginRight: 16 }} size={24} />
              {Math.round(currentCadence)}
              <span className={unitSize}>U/min</span>
            </h2>
          </div>
          <div className={classes.row}>
            <h2 className={metricSize}>
              <Icon src={Icons.heart} style={{ marginRight: 16 }} size={24} />
              {Math.round(currentHeartrate)}
              <span className={unitSize}>Hz</span>
            </h2>
          </div>
        </>
      )}
      <div className={classes.row}>
        <h2 className={metricSize}>
          <Icon src={Icons.speed} style={{ marginRight: 16 }} size={24} />
          {Math.round(currentSpeed * 3.6 * 10) / 10}
          <span className={unitSize}>km/h</span>
        </h2>
      </div>
      <div className={classes.row}>
        <h2 className={metricSize}>
          <Icon src={Icons.flag} style={{ marginRight: 16 }} size={24} />
          {distance}
          <span className={unitSize}>km</span>
        </h2>
      </div>
      <div className={classes.row}>
        <h2 className={metricSize}>
          <Icon src={Icons.mountain} style={{ marginRight: 16 }} size={24} />
          {Math.round(ridingElevation)}
          <span className={unitSize}>m</span>
        </h2>
        <h2 className={metricSize} style={{ marginLeft: 16 }}>
          {inclinePercent > 0 ? "+" : ""}
          {Math.round(inclinePercent)}
          <span className={unitSize}>%</span>
        </h2>
      </div>

      <div className={classes.row}>
        <h2 className={metricSize}>
          <Icon src={Icons.time} style={{ marginRight: 16 }} size={24} />
          {time}
          {!compact && <span className={unitSize}>min.</span>}
        </h2>
      </div>

      <div className={classes.row}>
        <h2 className={metricSize}>
          <Icon src={Icons.calories} style={{ marginRight: 16 }} size={24} />
          {Math.round(kcal)}
          <span className={unitSize}>kcal</span>
        </h2>
      </div>
    </div>
  );
};
