import { useSelector } from "react-redux";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { formatTime } from "../../../Core/utils";
import { getFtpZone } from "../../../Reducers/Bluetooth";
import Theme from "../../../Core/Theme";
import { WorkoutLoader } from "../WorkoutLoader";

const useStyles = makeStyles({
  workout: {
    backgroundColor: Theme.colors.white,
    fontFamily: Theme.font2P,
    display: "flex",
    flexDirection: "column",
  },
  row: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    "& p": {
      position: "absolute",
      width: 320,
      flex: 1,
      fontFamily: Theme.font2P,
      fontSize: 14,
      marginTop: 16,
      textAlign: "right",
    },
  },
  workoutSteps: {
    flex: 1,
    maxHeight: 400,
    overflowY: "scroll",
    scrollbars: "none",
  },
  background: {
    position: "relative",
    left: 0,
    top: 0,
    height: 44,
    width: 44,
  },
});

const zoneColors = Theme.zoneColors;

const WorkoutStep = ({ data, userFtp }) => {
  const classes = useStyles();
  const ref = useRef();

  const formatPower = (value) => {
    return Math.round(value * userFtp);
  };

  let zone;
  let powerLabel;
  if (data.type === "SteadyState") {
    zone = getFtpZone(data.power * 100, 100);
    powerLabel = formatPower(data.power);
  } else {
    zone = getFtpZone(Math.max(data.powerLow, data.powerHigh) * 100, 100);
    powerLabel = formatPower(data.powerLow) + "-" + formatPower(data.powerHigh);
  }
  const zoneColor = zoneColors[zone];

  if (data.active) {
    if (ref) {
      ref.current.scrollIntoView();
    }
  }

  return (
    <div className={classes.row} ref={ref}>
      <div
        className={classes.background}
        style={{
          backgroundColor: zoneColor,
          width: data.active ? Math.round((1 - data.progress) * 320) : 44,
        }}
      />
      <p>
        {powerLabel}W / {formatTime(Math.round(data.duration))}min
      </p>
    </div>
  );
};

export const Workout = () => {
  const classes = useStyles();

  const { workout, workoutLoaded } = useSelector((state) => state.Workout);
  const { ftp } = useSelector((state) => state.Player);

  return (
    <div className={classes.workout}>
      {!workoutLoaded && <h4 style={{ paddingTop: 16 }}>No workout loaded</h4>}
      <div className={classes.workoutSteps}>
        {workout.map((ws) => (
          <WorkoutStep key={"step" + ws.index} data={ws} userFtp={ftp} />
        ))}
      </div>
      <WorkoutLoader />
    </div>
  );
};
