import {
  Button,
  ButtonBase,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import Theme from "../../../Core/Theme";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detectCadence,
  detectDevices,
  detectFitnessMachine,
  detectHeartRate,
  detectPower,
  detectSpeed,
  deviceMap,
  setTireCircumference,
} from "../../../Reducers/Bluetooth/Actions";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import {
  setFtp,
  setGraphicsVariant,
  setMaxHr,
  setName,
  setWeight,
} from "../../../Reducers/Player/Actions";
import Graphics from "../../../Core/Graphics";
import Icons, { Icon } from "../../../Core/Icons";
import { useCookies } from "react-cookie";
import { sendJoin } from "../../../Reducers/Socket/Actions";
import { compact } from "qs/lib/utils";

const useStyles = makeStyles({
  setupOverlay: {
    backgroundColor: "#FFFFFF",
    maxWidth: 1000,
    maxHeight: 800,
    height: "80%",
    margin: "100px auto",
    padding: 24,
    fontFamily: Theme.font2P,
    "& p": {
      lineHeight: "24px",
    },
    overflowY: "auto",
  },
  inner: {
    position: "relative",
  },
  reconnectButton: {
    position: "absolute",
    right: 16,
    top: 16,
    "& img": {
      cursor: "pointer",
    },
  },
  headline: {
    fontFamily: Theme.font2P,
    fontSize: 48,
    marginTop: 0,
    textAlign: "center",
    color: Theme.colors.violett,
  },
  buttonConnect: {
    fontFamily: Theme.font2P,
    backgroundColor: Theme.colors.lightGrey,
    alignSelf: "flex-end",
  },
  connecting: {
    color: Theme.colors.black,
  },
  connected: {
    color: Theme.colors.green,
  },
  connectionError: {
    color: Theme.colors.fireRed,
  },
  box: {
    marginTop: 32,
    flex: 1,
    boxShadow: Theme.func.makeBox(Theme.colors.grey),
    margin: 16,
    textAlign: "center",
    fontFamily: Theme.font2P,
  },
  boxActive: {
    marginTop: 32,
    flex: 1,
    boxShadow: Theme.func.makeBox(Theme.colors.green),
    margin: 16,
    textAlign: "center",
  },
  boxDisabled: {
    marginTop: 32,
    flex: 1,
    boxShadow: Theme.func.makeBox(Theme.colors.grey),
    margin: 16,
    textAlign: "center",
    backgroundColor: Theme.colors.lightGrey,
    color: Theme.colors.grey,
  },
  inputRow: {
    marginBottom: 16,
    "& p": {
      display: "inline-block",
      marginTop: 24,
      marginLeft: 16,
      marginBottom: 0,
      fontSize: 12,
    },
  },
});

const LogView = () => {
  const [logs, setLogs] = useState(undefined);
  const [minimized, setMinimized] = useState(true);
  const endRef = useRef();

  window.addEventListener("logsUpdated", (e) => {
    setLogs(window._log);
  });

  if (endRef.current) {
    endRef.current.scrollIntoView({ block: "end" });
  }

  return (
    <div
      style={{
        position: "relative",
        fontSize: 12,
        textAlign: "left",
        padding: 16,
        height: minimized ? 16 : 200,
        maxHeight: 200,
        maxWidth: "100%",
        backgroundColor: "#EEEEEE",
        marginTop: 16,
      }}
    >
      {!minimized && (
        <div style={{ overflowY: "scroll", height: "100%", marginRight: 32 }}>
          {logs &&
            logs.map((line) => (
              <p style={{ margin: 0, fontSize: 14, fontFamily: "Roboto" }}>
                {line}
              </p>
            ))}
          <div ref={endRef} />
        </div>
      )}
      {minimized && (
        <p
          style={{ margin: 0, cursor: "pointer" }}
          onClick={() => setMinimized(!minimized)}
        >
          Connection Log
        </p>
      )}
      <ButtonBase
        onClick={() => setMinimized(!minimized)}
        style={{ position: "absolute", top: 8, right: 8 }}
      >
        {minimized ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      </ButtonBase>
    </div>
  );
};

const ConnectionButton = ({
  connecting,
  connected,
  error,
  label,
  onClickConnect,
  buttonDisabled,
  children,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <div
      className={
        disabled
          ? classes.boxDisabled
          : connected
          ? classes.boxActive
          : classes.box
      }
    >
      <h2>{label}</h2>
      {!connected && !connecting && (
        <div style={{ justifyItems: "flex-end" }}>
          {children}
          <Button
            disabled={buttonDisabled || disabled}
            className={classes.buttonConnect}
            onClick={onClickConnect}
          >
            Connect
          </Button>
        </div>
      )}
      {connecting && !connected && (
        <p className={classes.connecting}>CONNECTING...</p>
      )}
      {error && <p className={classes.connectionError}>ERROR</p>}
      {connected && <p className={classes.connected}>CONNECTED</p>}
    </div>
  );
};

const SetupStep1 = ({ onContinue, compact, overlayMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const bluetoothEnabled =
    !!navigator.bluetooth && !!navigator.bluetooth.getDevices;
  const [tireSize, setTireSize] = useState("");
  const [speedDisabled, setSpeedDisabled] = useState(true);

  const {
    hasPower,
    powerConnecting,
    powerConnectionError,
    hasCadence,
    cadenceConnecting,
    cadenceConnectionError,
    hasHeartrate,
    heartrateConnecting,
    heartrateConnectionError,
    hasFitnessMachine,
    fitnessMachineConnecting,
    fitnessMachineConnectionError,
    hasSpeed,
    speedConnecting,
    speedConnectionError,
  } = useSelector((state) => state.Bluetooth);

  const onConnectPower = () => {
    detectPower(dispatch);
  };
  const onConnectCadence = () => {
    detectCadence(dispatch);
  };
  const onConnectHeartrate = () => {
    detectHeartRate(dispatch);
  };
  const onConnectFitness = () => {
    detectFitnessMachine(dispatch);
  };
  const onConnectSpeed = () => {
    detectSpeed(dispatch);
  };
  const onReconnect = () => {
    detectDevices(dispatch);
  };

  const onChangeTireSize = (e) => {
    setTireSize(e.target.value);
    dispatch(setTireCircumference(e.target.value));
    setSpeedDisabled(false);
  };

  const hasDevices = Object.keys(deviceMap).length > 0;

  console.log("power " + powerConnectionError);
  console.log("fitness " + fitnessMachineConnectionError);

  return (
    <div className={classes.inner}>
      {hasDevices && bluetoothEnabled && (
        <div className={classes.reconnectButton}>
          <Icon src={Icons.sync} size={32} onClick={onReconnect} />
        </div>
      )}
      {!compact && !overlayMode && (
        <div style={{ position: "absolute", right: 0 }}>
          <p
            style={{ pointer: "cursor" }}
            onClick={() => {
              window.open(
                window.location.href + "?compact=true",
                "Ride2000",
                "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=250, height=500, top=0, left=0"
              );
            }}
          >
            Open Compact Mode
          </p>
        </div>
      )}
      <h1
        className={classes.headline}
        style={compact ? { fontSize: 24 } : undefined}
      >
        WELCOME TO
        <br />
        RIDE 2000
      </h1>
      {!compact && !overlayMode && (
        <p>
          This is the ultimate bike riding simulator with reality level over
          9000.
        </p>
      )}
      {!bluetoothEnabled && (
        <div>
          <p>
            To connect your bluetooth sensors, you must use this on chrome and
            activate the <b>experimental web platform features</b>. To activate
            them, open this URL in a new chrome tab (sorry, linking is not
            possible due to security reasons):{" "}
            <p
              style={{
                marginLeft: 24,
                fontSize: 12,
                color: Theme.colors.blue,
              }}
            >
              <i>chrome://flags/#enable-experimental-web-platform-features</i>
            </p>
            <p>After enabling the features, please reload this page.</p>
          </p>
        </div>
      )}
      {bluetoothEnabled && (
        <div>
          {!compact && !overlayMode && (
            <p>
              Let's connect your sensors. You can either connect a <b>power</b>{" "}
              sensor, if your trainer supports direct power output (usually
              direct drive trainers) or a <b>speed</b> sensor to use "virtual
              power".
            </p>
          )}
          {overlayMode && (
            <p>
              You launched RIDE 2000 in the overlay mode, have fun! If this was
              a mistake, <a href={"https://ride2000.com"}>click here</a> to go
              back to normal mode.
            </p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: compact ? "column" : "row",
            }}
          >
            <ConnectionButton
              label={"Power"}
              connected={hasPower}
              connecting={powerConnecting}
              error={powerConnectionError}
              onClickConnect={onConnectPower}
            />
            <ConnectionButton
              label={"Cadence"}
              connected={hasCadence}
              connecting={cadenceConnecting}
              error={cadenceConnectionError}
              onClickConnect={onConnectCadence}
            />
            <ConnectionButton
              label={"Heartrate"}
              connected={hasHeartrate}
              connecting={heartrateConnecting}
              error={heartrateConnectionError}
              onClickConnect={onConnectHeartrate}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: compact ? "column" : "row",
            }}
          >
            <ConnectionButton
              label={"Power Control"}
              connected={hasFitnessMachine}
              connecting={fitnessMachineConnecting}
              error={fitnessMachineConnectionError}
              onClickConnect={onConnectFitness}
            />
            <ConnectionButton
              disabled={hasPower}
              label={"Speed"}
              connected={hasSpeed}
              connecting={speedConnecting}
              error={speedConnectionError}
              onClickConnect={onConnectSpeed}
              buttonDisabled={speedDisabled}
            >
              <FormControl>
                <InputLabel>Tire Size</InputLabel>
                <Select
                  onChange={onChangeTireSize}
                  value={tireSize}
                  style={{ width: 180, marginBottom: 8, marginRight: 8 }}
                >
                  <MenuItem value={2086}>700x20C</MenuItem>
                  <MenuItem value={2096}>700x23C</MenuItem>
                  <MenuItem value={2105}>700x25C</MenuItem>
                  <MenuItem value={2130}>700C</MenuItem>
                  <MenuItem value={2136}>700x28C</MenuItem>
                </Select>
              </FormControl>
            </ConnectionButton>
          </div>
          {(hasPower || hasSpeed) && (
            <div>
              <p>
                Great. You connected a (virtual) power sensor. This is all we
                need.
              </p>
              <Button className={classes.buttonConnect} onClick={onContinue}>
                CONTINUE
              </Button>
            </div>
          )}
        </div>
      )}
      <LogView />
    </div>
  );
};

const SetupStep2 = ({ onContinue, compact }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["name", "weight", "ftp", "maxHr"]);

  const { name, ftp, maxHr, weight } = useSelector((state) => state.Player);

  const onChangeName = (name) => {
    setCookie("name", name, { path: "/" });
    dispatch(setName(name));
  };

  const onChangeWeight = (weight) => {
    setCookie("weight", weight, { path: "/" });
    dispatch(setWeight(weight));
  };

  const onChangeFtp = (ftp) => {
    setCookie("ftp", ftp, { path: "/" });
    dispatch(setFtp(ftp));
  };

  const onChangeMaxHr = (hr) => {
    setCookie("maxHr", hr, { path: "/" });
    dispatch(setMaxHr(hr));
  };

  return (
    <div className={classes.inner}>
      <h1 className={classes.headline}>WHO ARE YOU?</h1>
      {!compact && (
        <p>
          Alright, now it's getting personal. To give you a good riding
          experience, tell us a bit more about you.
        </p>
      )}
      <div>
        <div className={classes.inputRow}>
          <TextField
            label={"Name"}
            value={name}
            onChange={(e) => onChangeName(e.target.value)}
            onFocus={(event) => {
              event.target.select();
            }}
            style={{ width: 300 }}
          />
          {!compact && <p>To identify you in the lobby</p>}
        </div>
        <div className={classes.inputRow}>
          <TextField
            label={"Weight (kg)"}
            value={weight}
            onChange={(e) => onChangeWeight(e.target.value)}
            onFocus={(event) => {
              event.target.select();
            }}
            style={{ width: 200 }}
          />
          {!compact && <p>To correctly calculate elevation gains</p>}
        </div>
        <div className={classes.inputRow}>
          <TextField
            label={"FTP"}
            value={ftp}
            type={"number"}
            onChange={(e) => onChangeFtp(e.target.value)}
            onFocus={(event) => {
              event.target.select();
            }}
            style={{ width: 200 }}
          />
          {!compact && <p>To adjust workouts to your level</p>}
        </div>
        <div className={classes.inputRow}>
          <TextField
            label={"Max Heartrate"}
            value={maxHr}
            type={"number"}
            onChange={(e) => onChangeMaxHr(e.target.value)}
            onFocus={(event) => {
              event.target.select();
            }}
            style={{ width: 200 }}
          />
          {!compact && <p>To show your training zone based on HR</p>}
        </div>
      </div>
      <h3 style={{ marginTop: 32 }}>Select your player</h3>
      <RiderSelection />

      <Button className={classes.buttonConnect} onClick={onContinue}>
        CONTINUE
      </Button>
    </div>
  );
};

const RiderSelection = ({}) => {
  const dispatch = useDispatch();
  const riders = Graphics.riders;
  const [index, setIndex] = useState(undefined);
  console.log(riders);
  return (
    <div style={{ flexDirection: "row", display: "flex", marginBottom: 16 }}>
      {riders.map((file, i) => (
        <div
          onClick={() => {
            dispatch(setGraphicsVariant(i));
            setIndex(i);
          }}
          style={{
            cursor: "pointer",
            width: 368 * 0.2,
            height: 333 * 0.2,
            marginRight: 10,
            borderColor:
              i === index ? Theme.colors.violett : Theme.colors.white,
            borderWidth: 3,
            borderStyle: "solid",
            padding: 10,
          }}
        >
          <div
            style={{
              transform: "scale(0.2)",
              transformOrigin: "top left",
              background: "url('" + file + "')",
              backgroundPosition: "0,0",
              width: 368,
              height: 333,
            }}
          />
        </div>
      ))}
    </div>
  );
};

const SetupStep3 = ({ onContinue }) => {
  const classes = useStyles();

  return (
    <div className={classes.inner}>
      <h1 className={classes.headline}>BE A GIANT!</h1>
      <p>
        Great. You are set up to go. Now sip a final espresso, put the chain on
        the big dog and hit it!
      </p>
      <p>
        Note: This is a very early alpha version, if you encounter problems
        relax and please tell us. If you are missing features, please tell us
        too. And now get on that bike!
      </p>
      <Button className={classes.buttonConnect} onClick={onContinue}>
        BURN THESE CALORIES
      </Button>
    </div>
  );
};

export const SetupOverlay = ({ open, onClose, compact, overlay }) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);

  const onContinue = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      if (compact || overlay) {
        onClose();
      } else {
        setStep(3);
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal open={open}>
      <div
        className={classes.setupOverlay}
        style={
          compact
            ? { marginTop: 0, maxHeight: undefined, height: "100%" }
            : undefined
        }
      >
        {step === 1 && (
          <SetupStep1
            onContinue={onContinue}
            compact={compact}
            overlayMode={overlay}
          />
        )}
        {step === 2 && <SetupStep2 onContinue={onContinue} compact={compact} />}
        {step === 3 && <SetupStep3 onContinue={onContinue} compact={compact} />}
      </div>
    </Modal>
  );
};
