export const SET_TRACK = "SET_TRACK";
export const RESET_TRACK = "RESET_TRACK";
export const SET_CURRENT_POSITION = "SET_CURRENT_POSITION";

export const setCurrentPosition = (meters) => ({
  type: SET_CURRENT_POSITION,
  meters,
});

export const loadTrack = (xmlString) => (dispatch) => {
  try {
    let index = 0;
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const track = [];
    const points = xmlDoc.getElementsByTagName("trkpt");

    let totalDistance = 0;
    let totalElevationLoss = 0;
    let totalElevationGain = 0;
    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      const lat = point.getAttribute("lat");
      const lon = point.getAttribute("lon");
      let distance = 0;
      console.log("lat lng " + lat + " / " + lon);
      let elevation = 0;
      point.childNodes.forEach((child) => {
        if (child.nodeName === "ele") {
          elevation =
            Math.round(Number(child.childNodes[0].nodeValue) * 10) / 10;
        }
      });
      if (i > 0) {
        distance = getDistanceFromLatLonInMeters(
          track[i - 1].lat,
          track[i - 1].lon,
          lat,
          lon
        );
        console.log("distance from lat lon " + distance);
      }
      totalDistance += distance;
      track.push({
        lat,
        lon,
        elevation,
        distance,
      });
    }
    console.log(track);
    let lastElevation = undefined;
    const cleanedTrack = [];
    let ix = 0;
    track.forEach((pt) => {
      if (lastElevation === undefined) {
        cleanedTrack.push({ ...pt, elevationGain: 0, elevationLoss: 0 });
      } else {
        if (pt.elevation < lastElevation) {
          totalElevationLoss += lastElevation - pt.elevation;
        } else if (pt.elevation > lastElevation) {
          totalElevationGain += pt.elevation - lastElevation;
        }

        if (pt.elevation === lastElevation) {
          cleanedTrack[ix].distance += pt.distance;
        } else {
          cleanedTrack.push(pt);
          ix++;
        }
      }
      lastElevation = pt.elevation;
    });
    console.log(cleanedTrack);
    const cleanedTrack2 = [];
    let tempPt;
    let cleanDist = 30;
    cleanedTrack.forEach((pt) => {
      if (tempPt) {
        tempPt.distance += pt.distance;
        tempPt.elevation = pt.elevation;
        if (tempPt.distance > cleanDist) {
          cleanedTrack2.push({ ...tempPt });
          tempPt = undefined;
        }
      } else {
        if (pt.distance > cleanDist) {
          cleanedTrack2.push(pt);
        } else {
          tempPt = { ...pt };
        }
      }
    });
    console.log(cleanedTrack2);
    dispatch({
      type: SET_TRACK,
      track: cleanedTrack2,
      totalDistance,
      totalElevationGain,
      totalElevationLoss,
    });
  } catch (e) {
    console.log("parse error ", e);
  }
};

export const resetTrack = () => ({ type: RESET_TRACK });

function getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return Number(d * 1000); // distance in m
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
