import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createPlayerDataFromState,
  sendJoin,
  sendStravaExchangeToken,
  subscribe,
} from "../../Reducers/Socket/Actions";
import {
  setFtp,
  setMaxHr,
  setName,
  setUuid,
  setWeight,
  uuidv4,
} from "../../Reducers/Player/Actions";
import { TextField } from "@material-ui/core";
import { MetricsDisplay } from "../Components/RidingMetricsDisplay";
import { Lobby } from "../Components/Lobby";
import { useCookies } from "react-cookie";
import { SensorStatus } from "../Components/SensorStatus";
import { Histogram } from "../Components/Histogram";
import { Workout } from "../Components/Workout";
import { SensorValuesDisplay } from "../Components/SensorValuesDisplay";
import { RidingGraphics } from "../Components/RidingGraphics";
import { SetupOverlay } from "../Components/SetupOverlay";
import { DebugValues } from "../Components/DebugValues";
import { FinishOverlay } from "../Components/FinishOverlay";
import Theme from "../../Core/Theme";
import { Character } from "../Components/Character";
import packageJson from "../../../package.json";
import Track from "../Components/Track";
import WorkoutAndTrack from "../Components/WorkoutAndTrack";
import {
  receiveCadence,
  receivePower,
  sensorConnected,
} from "../../Reducers/Bluetooth/Actions";

const UserData = () => {
  const dispatch = useDispatch();
  const { name, weight, ftp, maxHr } = useSelector((state) => state.Player);
  const [cookies, setCookie] = useCookies(["name", "weight", "ftp", "maxHr"]);
  const [minimized, setMinimized] = useState(true);

  const onChangeName = (name) => {
    setCookie("name", name, { path: "/" });
    dispatch(setName(name));
    dispatch(sendJoin());
  };

  const onChangeWeight = (weight) => {
    setCookie("weight", weight, { path: "/" });
    dispatch(setWeight(weight));
  };

  const onChangeFtp = (ftp) => {
    setCookie("ftp", ftp, { path: "/" });
    dispatch(setFtp(ftp));
  };

  const onChangeMaxHr = (hr) => {
    setCookie("maxHr", hr, { path: "/" });
    dispatch(setMaxHr(hr));
  };

  if (minimized) {
    return (
      <div>
        <p onClick={() => setMinimized(false)}>User Settings</p>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p onClick={() => setMinimized(true)}>Hide</p>
        <TextField
          placeholder={"Your Name"}
          value={name}
          onChange={(e) => onChangeName(e.target.value)}
          style={{ width: 150 }}
        />
        <TextField
          placeholder={"Your Weight"}
          value={weight}
          type={"numeric"}
          onChange={(e) => onChangeWeight(e.target.value)}
          style={{ width: 150 }}
        />
        <TextField
          placeholder={"Your FTP"}
          value={ftp}
          type={"numeric"}
          onChange={(e) => onChangeFtp(e.target.value)}
          style={{ width: 150 }}
        />
        <TextField
          placeholder={"Your max. Heartrate"}
          value={maxHr}
          type={"numeric"}
          onChange={(e) => onChangeMaxHr(e.target.value)}
          style={{ width: 150 }}
        />
      </div>
    );
  }
};

const isDebug = process.env.NODE_ENV !== "production";
export const Main = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["uuid", "name"]);
  const [showSetup, setShowSetup] = useState(true);
  const [showFinish, setShowFinish] = useState(false);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const [compactMode, setCompactMode] = useState(params.compact === "true");
  const [overlayMode, setOverlayMode] = useState(params.overlay === "true");

  useEffect(() => {
    // get uuid from cookie
    if (cookies.uuid) {
      dispatch(setUuid(cookies.uuid));
    } else {
      const uuid = uuidv4();
      setCookie("uuid", uuid, { path: "/" });
    }
    // get name from cookie
    if (cookies.name) {
      dispatch(setName(cookies.name));
    }
    if (cookies.ftp) {
      dispatch(setFtp(cookies.ftp));
    }
    if (cookies.maxHr) {
      dispatch(setMaxHr(cookies.maxHr));
    }
    if (cookies.weight) {
      dispatch(setWeight(cookies.weight));
    }
    window.addEventListener(
      "message",
      (event) => {
        console.log("message received", event.data);
        const data = JSON.parse(event.data);
        switch (data.type) {
          case "power":
            dispatch(receivePower(data.power));
            break;
          case "cadence":
            dispatch(
              receiveCadence(data.eventTime, data.cumulativeRevolutions)
            );
            break;
          case "connected":
            dispatch(sensorConnected(data.sensorType));
            break;
          case "disconnected":
            break;
        }
      },
      false
    );
  }, []);

  const { players, connected } = useSelector((state) => state.Socket);
  const playerData = useSelector((state) => createPlayerDataFromState(state));
  const { uuid } = useSelector((state) => state.Player);
  const { lastChange } = useSelector((state) => state.Bluetooth);
  const { currentMessage } = useSelector((state) => state.Workout);
  const { inclineDegrees } = useSelector((state) => state.Track);
  const [secondsLeft, setSecondsLeft] = useState(10);
  const lastChangeRef = useRef();
  const finishTimeoutRef = useRef();

  useEffect(() => {
    if (uuid && !connected) {
      dispatch(subscribe());
    }
  }, [uuid, connected]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (connected && code) {
      dispatch(sendStravaExchangeToken(code));
    }
  }, [connected]);

  useEffect(() => {
    clearTimeout(lastChangeRef.current);
    clearInterval(finishTimeoutRef.current);
    setSecondsLeft(10);
    if (lastChange) {
      let i = 13;
      finishTimeoutRef.current = setInterval(() => {
        if (i === 0) {
          setShowFinish(true);
          clearInterval(finishTimeoutRef.current);
        }
        setSecondsLeft(i--);
      }, 1000);
    }
  }, [lastChange]);

  return (
    <div
      style={{
        height: "100%",
        flexDirection: "column",
        display: "flex",
        backgroundColor: isDebug ? "#99999999" : undefined,
      }}
    >
      <SetupOverlay
        open={showSetup}
        onClose={() => setShowSetup(false)}
        compact={compactMode}
        overlay={overlayMode}
      />
      <FinishOverlay
        open={showFinish}
        onClose={() => setShowFinish(false)}
        compact={compactMode}
      />
      {secondsLeft < 10 && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "#FFFFFFBB",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "calc( 50% - 150px )",
              textAlign: "center",
              fontFamily: Theme.font2P,
              fontSize: 48,
              width: "100%",
              zIndex: 100,
            }}
          >
            {secondsLeft}
          </div>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: compactMode ? "column" : "row",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <MetricsDisplay
            compact={compactMode || overlayMode}
            overlay={overlayMode}
          />
        </div>
        {compactMode && <WorkoutAndTrack compact={true} />}
        <div style={{ flex: 1 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!compactMode && !overlayMode && <SensorValuesDisplay />}
          </div>
          {!compactMode && (
            <div
              style={
                overlayMode
                  ? { width: 200, position: "absolute", top: 0, right: 16 }
                  : {}
              }
            >
              <Histogram />
            </div>
          )}
          {isDebug && (
            <div
              style={{
                position: "absolute",
                bottom: 16,
                right: 16,
                zIndex: 100,
              }}
            >
              <DebugValues />
            </div>
          )}
        </div>
        {!overlayMode && (
          <div
            style={{
              width: compactMode ? undefined : 344,
            }}
          >
            <p style={{ fontFamily: Theme.font2P, fontSize: 10 }}>
              Version {packageJson.version}
            </p>
            <p
              onClick={() => {
                setCompactMode(!compactMode);
              }}
            >
              {compactMode ? "Full Mode" : "Compact Mode"}
            </p>
            <SensorStatus />
          </div>
        )}
      </div>
      {!compactMode && !overlayMode && (
        <div style={{ flex: 1, position: "relative" }}>
          <RidingGraphics
            player={playerData}
            players={
              showSetup
                ? []
                : players && players.length > 0
                ? players
                : [playerData]
            }
            slopeDegrees={inclineDegrees}
          />
          <div style={{ position: "absolute", top: 20 }}>
            <WorkoutAndTrack />
          </div>
        </div>
      )}
      {!compactMode && !overlayMode && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1 }}>
            <Lobby />
          </div>
        </div>
      )}
      {!compactMode && (
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", bottom: 0, right: -200 }}>
            <Character text={currentMessage} />
          </div>
        </div>
      )}
      {overlayMode && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Track overlay={true} />
        </div>
      )}
    </div>
  );
};
