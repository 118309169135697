import {
  SET_CURRENT_TIME,
  SET_WORKOUT,
  START_WORKOUT,
  STOP_WORKOUT,
} from "./Actions";
import { act } from "@testing-library/react";

export const initialState = {
  workout: [],
  workoutDuration: 0,
  workoutLoaded: false,
  workoutStarted: false,
  activeStep: 0,
  workoutTime: 0,
  activeFtp: 0,
  currentMessage: undefined,
  remainingTimeInStep: 0,
};

export default function PlayerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_WORKOUT:
      return {
        ...state,
        workout: action.workout,
        workoutDuration: action.duration,
        workoutLoaded: true,
      };

    case START_WORKOUT:
      return { ...state, workoutStarted: true };

    case STOP_WORKOUT:
      return { ...state, workoutStarted: false, workoutFinished: true };

    case SET_CURRENT_TIME: {
      let activeStep = 0;
      let steps = 0;
      let totalCheckTime = 0;
      let timeInStep = 0;
      let remainingTimeInStep = 0;
      state.workout.forEach((ws) => {
        const totalCheckEnd = totalCheckTime + ws.duration;
        if (action.time >= totalCheckTime && action.time < totalCheckEnd) {
          activeStep = ws.index;
          timeInStep = action.time - totalCheckTime;
          ws.progress = timeInStep / ws.duration;
          remainingTimeInStep = ws.duration - timeInStep;
        }
        totalCheckTime = totalCheckEnd;
        steps++;
      });

      const activeWorkoutStep = state.workout.find(
        (ws) => ws.index === activeStep
      );

      let activeFtp = 0;
      if (activeWorkoutStep.powerLow && activeWorkoutStep.powerHigh) {
        const { powerHigh, powerLow, duration } = activeWorkoutStep;
        const stepSize = (powerHigh - powerLow) / duration;
        activeFtp = powerLow + stepSize * timeInStep;
      } else {
        activeFtp = activeWorkoutStep.power;
      }

      let currentMessage = undefined;
      if (activeWorkoutStep.texts && activeWorkoutStep.texts.length > 0) {
        activeWorkoutStep.texts.forEach((text) => {
          if (
            timeInStep >= text.time &&
            timeInStep <= Number(text.time) + Number(text.duration)
          ) {
            currentMessage = text.message;
            console.log(timeInStep + " -> " + currentMessage);
          }
        });
      }

      return {
        ...state,
        workoutTime: action.time,
        activeStep: activeWorkoutStep,
        activeFtp,
        remainingTimeInStep,
        workout: state.workout.map((ws) => ({
          ...ws,
          active: ws.index === activeStep,
        })),
        currentMessage,
      };
    }

    default:
      return state;
  }
}
