export default {
  font2P: '"Press Start 2P", cursive',
  textShadow: "2px -1px rgb(0 0 0 / 60%)",
  colors: {
    black: "#000000",
    grey: "#888888",
    lightGrey: "#DDDDDD",
    blue: "#3333CC",
    fireRed: "#EE3300",
    violett: "#3300AA",
    green: "#33CC33",
    white: "#FFFFFF",
  },
  zoneColors: {
    1: "#E8E8E5",
    2: "#6080FF",
    3: "#68DC4C",
    4: "#FFE330",
    5: "#FE9925",
    6: "#E52121",
  },
  func: {
    makeBox: (borderColor) => {
      return (
        "-8px 0 0 0 " +
        borderColor +
        ", 8px 0 0 0 " +
        borderColor +
        ", 0 -8px 0 0 " +
        borderColor +
        ", 0 8px 0 0 " +
        borderColor +
        ""
      );
    },
  },
};
