import { useSelector } from "react-redux";
import React from "react";
import { makeStyles } from "@material-ui/core";
import useDimensions from "react-use-dimensions";
import Theme from "../../../Core/Theme";

const useStyles = makeStyles({
  histogram: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: 0,
    marginBottom: 16,
    height: 160,
    width: "100%",
    overflowX: "hidden",
  },
  bar: {
    width: 4,
    marginRight: 1,
  },
});

const zoneColors = Theme.zoneColors;

export const FinishHistogram = ({}) => {
  const classes = useStyles();
  const { powerHistory, heartrateHistory } = useSelector(
    (state) => state.Bluetooth
  );
  const [ref, { x, y, width }] = useDimensions();

  const maxBars = Math.floor(width / 5);

  // we create a combined map with the timestamp as key to sync the values of both histories on the time axis
  const syncedHistory = {};
  powerHistory.forEach((ph) => {
    syncedHistory[ph.time] = { power: ph };
  });
  heartrateHistory.forEach((hh) => {
    if (syncedHistory[hh.time]) {
      syncedHistory[hh.time].heartrate = hh;
    }
  });

  // then we create an array from this map
  const syncedHistoryArr = [];
  Object.keys(syncedHistory).forEach((key) => {
    syncedHistoryArr.push(syncedHistory[key]);
  });

  let divisor = 1;
  // if history is bigger than max bars, combine the values
  const combinedArray = [];
  if (syncedHistoryArr.length > maxBars) {
    divisor = Math.ceil(syncedHistoryArr.length / maxBars);
  }

  for (let i = 0; i < syncedHistoryArr.length; i += divisor) {
    let power = 0;
    let heartrate = 0;
    let entries = 0;
    for (let j = 0; j < divisor; j++) {
      if (syncedHistoryArr[i + j]) {
        entries++;
        power += syncedHistoryArr[i + j].power
          ? Number(syncedHistoryArr[i + j].power.power)
          : 0;
        heartrate += syncedHistoryArr[i + j].heartrate
          ? Number(syncedHistoryArr[i + j].heartrate.heartrate)
          : 0;
      }
    }
    combinedArray.push({
      power: power / entries,
      heartrate: heartrate / entries,
    });
  }

  const bars = combinedArray.map((sync) => sync.power);
  const points = combinedArray.map((sync) => sync.heartrate);

  return (
    <div style={{ position: "relative" }}>
      <div className={classes.histogram} ref={ref}>
        {bars &&
          bars.map((ph) =>
            ph ? (
              <div
                style={{
                  height: Math.round(ph / 2.5),
                  backgroundColor: "#999999",
                }}
                className={classes.bar}
              />
            ) : null
          )}
      </div>
      <div style={{ position: "absolute", top: 0 }}>
        {points &&
          points.map((hr, i) =>
            hr ? (
              <div
                style={{
                  position: "absolute",
                  left: i * 5,
                  top: 70 - Number(hr) / 3,
                  width: 2,
                  height: 2,
                  backgroundColor: "#C00",
                }}
              />
            ) : null
          )}
      </div>
    </div>
  );
};
