import { log } from "../../App";
import { sendPlayerData } from "../Socket/Actions";
import ls from "local-storage";
import { setCurrentPosition } from "../Track/Actions";

export const RECEIVE_POWER = "BT_RECEIVE_POWER";
export const RECEIVE_CADENCE = "BT_RECEIVE_CADENCE";
export const RECEIVE_CADENCE_DEBUG = "BT_RECEIVE_CADENCE_DEBUG";
export const RECEIVE_HEARTRATE = "BT_RECEIVE_HEARTRATE";
export const RECEIVE_SPEED = "BT_RECEIVE_SPEED";
export const SENSOR_CONNECTED = "BT_SENSOR_CONNECTED";
export const SET_USER_FTP = "BT_SET_USER_FTP";
export const SET_USER_MAX_HR = "BT_SET_USER_MAX_HR";
export const SET_USER_WEIGHT = "BT_SET_USER_WEIGHT";
export const SET_TIRE_CIRCUMFERENCE = "BT_SET_TIRE_CIRCUMFERENCE";

export const CONNECTION_FAILED = "BT_CONNECTION_FAILED";
export const START_CONNECTING = "BT_START_CONNECTING";

export const SENSOR_TYPE_POWER = "BT_SENSOR_TYPE_POWER";
export const SENSOR_TYPE_CADENCE = "BT_SENSOR_TYPE_CADENCE";
export const SENSOR_TYPE_SPEED = "BT_SENSOR_TYPE_SPEED";
export const SENSOR_TYPE_HEARTRATE = "BT_SENSOR_TYPE_HEARTRATE";
// not actually a sensor, but who cares
export const SENSOR_TYPE_FITNESS_MACHINE = "BT_SENSOR_FITNESS_MACHINE";

export let deviceMap = ls.get("deviceMap");
if (!deviceMap) {
  deviceMap = {};
}
console.log("mapped devices: ");
console.log(deviceMap);
const pairedDevices = {};

let fitnessMachineControlPointCharacteristic = undefined;

export const clearPairedDevices = () => {
  deviceMap = {};
  ls.set("deviceMap", deviceMap);
};

export const setUserFtp = (userFtp) => ({
  type: SET_USER_FTP,
  userFtp,
});

export const setUserMaxHr = (userMaxHr) => ({
  type: SET_USER_MAX_HR,
  userMaxHr,
});

export const setUserWeight = (userWeight) => ({
  type: SET_USER_WEIGHT,
  userWeight,
});

export const receivePower = (power) => async (dispatch, getState) => {
  const { inclinePercent } = getState().Track;
  await dispatch({
    type: RECEIVE_POWER,
    power,
    slope: inclinePercent,
  });
  // lets update track data
  const { ridingDistance } = getState().Bluetooth;
  dispatch(setCurrentPosition(ridingDistance));

  // now update state via socket
  dispatch(sendPlayerData());
};

export const receivePowerDebug = (power, slope) => (dispatch) => {
  dispatch({ type: RECEIVE_POWER, power, slope });
};

export const receiveCadence = (eventTime, totalRevolutions) => ({
  type: RECEIVE_CADENCE,
  eventTime,
  totalRevolutions,
});

export const receiveCadenceDebug = (cadence) => ({
  type: RECEIVE_CADENCE_DEBUG,
  cadence,
});

export const receiveSpeed = (eventTime, totalRevolutions) => (dispatch) => {
  dispatch({
    type: RECEIVE_SPEED,
    eventTime,
    totalRevolutions,
  });
  // now update state via socket
  dispatch(sendPlayerData());
};

export const receiveHeartrate = (heartrate) => ({
  type: RECEIVE_HEARTRATE,
  heartrate,
});

export const sensorConnected = (sensorType) => ({
  type: SENSOR_CONNECTED,
  sensorType,
  status: true,
});

export const sensorDisconnected = (sensorType) => ({
  type: SENSOR_CONNECTED,
  sensorType,
  status: false,
});

export const startConnecting = (sensorType) => ({
  type: START_CONNECTING,
  sensorType,
});

export const connectionFailed = (sensorType, reconnect) => ({
  type: CONNECTION_FAILED,
  sensorType,
  reconnect,
});

export const setTireCircumference = (circumference) => ({
  type: SET_TIRE_CIRCUMFERENCE,
  circumference,
});

export const detectDevices = async (dispatch) => {
  // service cycling_power:
  //   cycling_power_control_point
  //   cycling_power_feature
  //   cycling_power_measurement
  //   cycling_power_vector

  // service cycling_speed_and_cadence:
  //   csc_feature
  //   csc_measurement
  await reconnectExistingDevices();

  detectPower(dispatch, true);
  detectCadence(dispatch, true);
  detectHeartRate(dispatch, true);
  detectSpeed(dispatch, true);
  connectFitnessMachine(dispatch, true);
};

export const detectPower = async (dispatch, reconnect) => {
  log("search power sensor");
  dispatch(startConnecting(SENSOR_TYPE_POWER));
  const powerConnected = await connectPowerMeter(dispatch, reconnect);
  if (powerConnected) {
    log("power sensor connected");
    dispatch(sensorConnected(SENSOR_TYPE_POWER));
    // power sensor devices usually also have a cadence sensor, so we try to connect cacence here too
    detectCadence(dispatch, false);
  } else {
    dispatch(connectionFailed(SENSOR_TYPE_POWER, reconnect));
  }
};

export const detectCadence = async (dispatch, reconnect) => {
  log("search cadence sensor");
  dispatch(startConnecting(SENSOR_TYPE_CADENCE));
  const cadenceConnected = await connectCadence(dispatch, reconnect);
  if (cadenceConnected) {
    log("cadence sensor connected");
    dispatch(sensorConnected(SENSOR_TYPE_CADENCE));
  } else {
    dispatch(connectionFailed(SENSOR_TYPE_CADENCE, reconnect));
  }
};

export const detectSpeed = async (dispatch, reconnect) => {
  log("search speed sensor");
  dispatch(startConnecting(SENSOR_TYPE_SPEED));
  const speedConnected = await connectSpeed(dispatch, reconnect);
  if (speedConnected) {
    log("speed sensor connected");
    dispatch(sensorConnected(SENSOR_TYPE_SPEED));
  } else {
    dispatch(connectionFailed(SENSOR_TYPE_SPEED, reconnect));
  }
};

export const detectHeartRate = async (dispatch, reconnect) => {
  log("search heart rate sensor");
  dispatch(startConnecting(SENSOR_TYPE_HEARTRATE));
  const heartRateConnected = await connectHeartRate(dispatch, reconnect);
  if (heartRateConnected) {
    log("heart rate sensor connected");
    dispatch(sensorConnected(SENSOR_TYPE_HEARTRATE));
  } else {
    dispatch(connectionFailed(SENSOR_TYPE_HEARTRATE, reconnect));
  }
};

export const detectFitnessMachine = async (dispatch) => {
  await connectFitnessMachine(dispatch);
};

const reconnectExistingDevices = async () => {
  try {
    log("Getting existing permitted Bluetooth devices...");
    const devices = await navigator.bluetooth.getDevices();

    log("> Got " + devices.length + " Bluetooth devices.");
    for (const device of devices) {
      log("  > " + device.name + " (" + device.id + ")");
      pairedDevices[device.id] = device;
    }
  } catch (error) {
    log("Argh! " + error);
  }
};

const connectPowerMeter = async (dispatch, reconnect) => {
  // https://www.bluetooth.com/wp-content/uploads/Sitecore-Media-Library/Gatt/Xml/Characteristics/org.bluetooth.characteristic.cycling_power_measurement.xml

  return connectServiceAndListenToCharacteristics(
    "cycling_power",
    "cycling_power_measurement",
    "power",
    reconnect,
    (event) => {
      const value = event.target.value;

      // currently we only need the first four bytes, so we slice them
      const raw16 = new Int16Array(value.buffer.slice(0, 4));
      // get flags
      // const flags = reverseString(raw16[0].toString(2));
      // console.log("power flags: " + flags);
      dispatch(receivePower(raw16[1]));
      dispatch(sensorConnected(SENSOR_TYPE_POWER));
    },
    (event) => {
      dispatch(sensorDisconnected(SENSOR_TYPE_POWER));
    }
  );
};

const connectCadence = async (dispatch, reconnect) => {
  return connectServiceAndListenToCharacteristics(
    "cycling_speed_and_cadence",
    "csc_measurement",
    "cadence",
    reconnect,
    (event) => {
      // https://www.bluetooth.com/wp-content/uploads/Sitecore-Media-Library/Gatt/Xml/Characteristics/org.bluetooth.characteristic.csc_measurement.xml

      const value = event.target.value;
      //console.log(value);
      //console.log(value.buffer);
      const buffer = value.buffer;

      const rawu8 = new Uint8Array(buffer.slice(0, 1));
      const flags = reverseString(rawu8[0].toString(2));
      //console.log("cadence flags: " + flags);
      const wheelFlag = flags[0];
      const crankFlag = flags[1];

      //log("cadence sensor has wheel revolutions? " + wheelFlag);
      //log("cadence sensor has crank revolutions? " + crankFlag);

      let cumulativeWheelRevolutions = 0;
      let lastWheelEventTime = undefined;
      let cumulativeCrankRevolutions = 0;
      let lastCrankEventTime = undefined;
      let nextIndex = 1;
      if (wheelFlag) {
        const rawu32 = new Uint32Array(buffer.slice(1, 5));
        const rawu16 = new Uint16Array(buffer.slice(5, 7));
        cumulativeWheelRevolutions = rawu32[0];
        lastWheelEventTime = rawu16[0];
        nextIndex = 7;
        //console.log("wheel revolutions " + cumulativeWheelRevolutions);
        //console.log("wheel time " + lastWheelEventTime);
      }
      if (crankFlag) {
        const rawu16 = new Uint16Array(buffer.slice(nextIndex, nextIndex + 4));
        cumulativeCrankRevolutions = rawu16[0];
        lastCrankEventTime = rawu16[1];
        //console.log("crank revolutions: " + cumulativeCrankRevolutions);
        //console.log("crank time: " + lastCrankEventTime);
        dispatch(
          receiveCadence(lastCrankEventTime, cumulativeCrankRevolutions)
        );
      }
    },
    (event) => {
      dispatch(sensorDisconnected(SENSOR_TYPE_CADENCE));
    }
  );
};

const connectSpeed = async (dispatch, reconnect) => {
  return connectServiceAndListenToCharacteristics(
    "cycling_speed_and_cadence",
    "csc_measurement",
    "speed",
    reconnect,
    (event) => {
      // https://www.bluetooth.com/wp-content/uploads/Sitecore-Media-Library/Gatt/Xml/Characteristics/org.bluetooth.characteristic.csc_measurement.xml

      const value = event.target.value;
      //console.log(value);
      //console.log(value.buffer);
      const buffer = value.buffer;

      const rawu8 = new Uint8Array(buffer.slice(0, 1));
      const flags = reverseString(rawu8[0].toString(2));
      console.log("sensor flags: " + flags);
      const wheelFlag = flags[0];
      const crankFlag = flags[1];

      log("sensor has wheel revolutions? " + wheelFlag);
      //log("cadence sensor has crank revolutions? " + crankFlag);

      let cumulativeWheelRevolutions = 0;
      let lastWheelEventTime = undefined;
      let cumulativeCrankRevolutions = 0;
      let lastCrankEventTime = undefined;
      let nextIndex = 1;
      if (wheelFlag) {
        const rawu32 = new Uint32Array(buffer.slice(1, 5));
        const rawu16 = new Uint16Array(buffer.slice(5, 7));
        cumulativeWheelRevolutions = rawu32[0];
        lastWheelEventTime = rawu16[0];
        nextIndex = 7;
        console.log("wheel revolutions " + cumulativeWheelRevolutions);
        console.log("wheel time " + lastWheelEventTime);
        dispatch(receiveSpeed(lastWheelEventTime, cumulativeWheelRevolutions));
      }
    }
  );
};

const connectHeartRate = async (dispatch, reconnect) => {
  return connectServiceAndListenToCharacteristics(
    "heart_rate",
    "heart_rate_measurement",
    "heart_rate",
    reconnect,
    (event) => {
      const value = event.target.value;
      //console.log(value);
      //console.log(value.buffer);

      // https://www.bluetooth.com/wp-content/uploads/Sitecore-Media-Library/Gatt/Xml/Characteristics/org.bluetooth.characteristic.heart_rate_measurement.xml
      const rawu8 = new Uint8Array(value.buffer.slice(0, 2));
      const flags = reverseString(rawu8[0].toString(2));
      const formatFlag = flags[0];
      let hr = 0;
      // flag is string
      if (formatFlag === "0") {
        // UNIT 8 in field C1
        hr = rawu8[1];
      } else {
        const rawu16 = new Uint16Array(value.buffer.slice(1, 3));
        hr = rawu16[1];
      }
      dispatch(receiveHeartrate(hr));
    }
  );
};

const connectFitnessMachine = async (dispatch, reconnect) => {
  dispatch(startConnecting(SENSOR_TYPE_FITNESS_MACHINE));

  // all bluetooth uuids
  // https://btprodspecificationrefs.blob.core.windows.net/assigned-values/16-bit%20UUID%20Numbers%20Document.pdf
  // FTMS specs: https://www.bluetooth.com/specifications/specs/fitness-machine-service-1-0/
  // see page 50: setting of control points ... set target power
  // procedure:
  // send request control LSO: 0x00 MSO: empty -> will be confirmed with LSO: 0x80
  // send set target power LSO: 0x05 UINT8 | LSO...MSO: power value SINT16 -> will be confirmed with 0x80
  // XML: https://raw.githubusercontent.com/oesmith/gatt-xml/master/org.bluetooth.service.fitness_machine.xml
  // service
  // 0x1826 fitness machine GATT Service
  // characteristics
  // 0x2ACC fitness machine feature
  // 0x2AD9 fitness machine control point
  // 0x2ADA fitness machine status
  // the available characteristic is 0x2902 -> we will write to that one

  // Fitness Machine GATT Service
  const serviceUuid = parseInt("0x1826");
  // Fitness Machine Control Point
  const characteristicUuid = "00002ad9-0000-1000-8000-00805f9b34fb";
  // User Descriptor Characteristics
  const descriptorUuid = parseInt("0x2902");

  try {
    let device;
    if (deviceMap[serviceUuid] && pairedDevices[deviceMap[serviceUuid]]) {
      log("use already paired device: " + deviceMap[serviceUuid]);
      device = pairedDevices[deviceMap[serviceUuid]];
    } else if (!reconnect) {
      log("Requesting Bluetooth Device...");
      device = await navigator.bluetooth.requestDevice({
        filters: [{ services: [serviceUuid] }],
      });
      deviceMap[serviceUuid] = device.id;
      pairedDevices[device.id] = device;
      ls.set("deviceMap", deviceMap);
    } else {
      dispatch(connectionFailed(SENSOR_TYPE_FITNESS_MACHINE, reconnect));
      return false;
    }

    log("Connecting to GATT Server...");
    const server = await device.gatt.connect();

    log("Getting Service...");
    const service = await server.getPrimaryService(serviceUuid);

    log("Getting Characteristics...");

    // get machine feature characteristic: 0x2ACC
    let characteristics = await service.getCharacteristics(parseInt("0x2acc"));
    let characteristic = characteristics[0];
    let result = await characteristic.readValue();
    log("machine features");
    log(result);

    // subscribe to feature changes
    characteristics = await service.getCharacteristics(parseInt("0x2ada"));
    characteristic = characteristics[0];
    characteristic.addEventListener("characteristicvaluechanged", (value) => {
      log("machine status changed");
      log(value);
      log(value.currentTarget.value);
    });
    characteristic.startNotifications();

    // directly write to the characteristic
    // send request control LSO: 0x00 MSO: empty -> will be confirmed with LSO: 0x80
    // send set target power LSO: 0x05 UINT8 | LSO...MSO: power value SINT16 -> will be confirmed with 0x80

    const buffer1 = new ArrayBuffer(1);
    const dataview1 = new DataView(buffer1);
    dataview1.setUint8(0, 0x00);

    const writeChar = await service.getCharacteristic(characteristicUuid);
    // we have to start notifications on the characteristic to be allowed to write
    writeChar.startNotifications();

    // get descriptor
    // let descriptor = await writeChar.getDescriptor(descriptorUuid);
    //const descVal = Uint8Array.of(0x03);
    //await descriptor.writeValue(descVal);
    //    console.log("configured descriptor");
    //    const desc2 = await descriptor.readValue();
    //    console.log(desc2);

    // init flag is 0x00
    const test = Uint8Array.of(0);
    await writeChar.writeValueWithResponse(test);
    // let result = await characteristic.readValue();
    log(writeChar);
    log("sent init");

    // set resistance (we dont need this atm)
    /*const buffer2 = new ArrayBuffer(2);
    const dataview2 = new DataView(buffer2);
    dataview2.setUint8(0, 0x04); // setpoint flag 0x05
    dataview2.setUint8(1, Math.floor(Number(resistance) * 255)); // third param is endianness
    await writeChar.writeValue(buffer2);
    console.log("resistance written to " + resistance);*/

    // create buffer with length 3: 1 byte for control flag + 2 bytes for power setpoint
    /*const buffer = new ArrayBuffer(3);
    const dataview = new DataView(buffer);
    dataview.setUint8(0, 0x05); // setpoint flag 0x05 <- CORRECT; DONT CHANGE
    dataview.setInt16(1, Number(power), true); // third param is endianness <- CORRECT; DONT CHANGE

    // and now we transfer the value
    await writeChar.writeValue(buffer);*/

    // console.log("Getting Descriptor...");
    // const descriptor = await characteristic.getDescriptor(descriptorUuid);
    // descriptor.writeValue();

    fitnessMachineControlPointCharacteristic = writeChar;
    dispatch(sensorConnected(SENSOR_TYPE_FITNESS_MACHINE));

    return true;
    // power.readValue();
  } catch (error) {
    log("Bluetooth error: " + error);
    log(error);
    dispatch(connectionFailed(SENSOR_TYPE_FITNESS_MACHINE, reconnect));

    return false;
  }
};

export const setFitnessMachineTargetPower = (targetPower) => async (
  dispatch
) => {
  if (fitnessMachineControlPointCharacteristic) {
    // create buffer with length 3: 1 byte for control flag + 2 bytes for power setpoint
    const buffer = new ArrayBuffer(3);
    const dataview = new DataView(buffer);
    dataview.setUint8(0, 0x05); // setpoint flag 0x05 <- CORRECT; DONT CHANGE
    dataview.setInt16(1, Number(targetPower), true); // third param is endianness <- CORRECT; DONT CHANGE
    // and now we transfer the value
    await fitnessMachineControlPointCharacteristic.writeValue(buffer);
    console.log("new target value written " + targetPower);
  } else {
    console.error("fitness machine control point missing");
  }
};

const connectServiceAndListenToCharacteristics = async (
  serviceUuid,
  characteristic,
  type,
  reconnect,
  listener,
  disconnectListener,
  skipReconnectListener = false
) => {
  try {
    let device;
    const devicekey = serviceUuid + "-" + type;
    if (deviceMap[devicekey] && pairedDevices[deviceMap[devicekey]]) {
      log("use already paired device: " + deviceMap[devicekey]);
      device = pairedDevices[deviceMap[devicekey]];
    } else if (!reconnect) {
      log("Requesting Bluetooth Device...");
      device = await navigator.bluetooth.requestDevice({
        filters: [{ services: [serviceUuid] }],
      });
      deviceMap[devicekey] = device.id;
      pairedDevices[device.id] = device;
      ls.set("deviceMap", deviceMap);
    } else {
      return false;
    }

    log("Connecting to GATT Server...");
    const server = await device.gatt.connect();

    // add disconnect listener
    if (!skipReconnectListener) {
      console.log("add disconnect listener");
      device.addEventListener("gattserverdisconnected", (event) => {
        // Object event.target is Bluetooth Device getting disconnected.
        log("> Bluetooth Device disconnected: " + devicekey);
        console.log(event);
        console.log("reconnect");
        disconnectListener && disconnectListener(event);
        connectServiceAndListenToCharacteristics(
          serviceUuid,
          characteristic,
          type,
          reconnect,
          listener,
          disconnectListener,
          true
        );
      });
    }

    log("Getting Service... " + serviceUuid);
    const service = await server.getPrimaryService(serviceUuid);

    log("Getting Characteristics...");
    const characteristics = await service.getCharacteristic(characteristic);
    log("Add eventlistener");
    characteristics.addEventListener("characteristicvaluechanged", listener);
    characteristics.startNotifications();
    return true;
    // power.readValue();
  } catch (error) {
    log("Bluetooth error: " + error);
    return false;
  }
};

function dec2bin(dec) {
  return (dec >>> 0).toString(2);
}

function reverseString(str) {
  return str.split("").reverse().join("");
}
