export default {
  cadence: "/img/icons/cadence.png",
  calories: "/img/icons/calories.png",
  check: "/img/icons/check.png",
  flag: "/img/icons/flag.png",
  floppy: "/img/icons/floppy.png",
  greenArrow: "/img/icons/green-arrow.png",
  greenLight: "/img/icons/green-light.png",
  heart: "/img/icons/heart.png",
  menuToggle: "/img/icons/menu-toggle.png",
  mountain: "/img/icons/mountain.png",
  pause: "/img/icons/pause.png",
  play: "/img/icons/play.png",
  plus: "/img/icons/plus.png",
  progressBar: "/img/icons/progress-bar.png",
  progressBarDot: "/img/icons/progress-bar-dot.png",
  redArrow: "/img/icons/red-arrow.png",
  request: "/img/icons/request.png",
  speed: "/img/icons/speed.png",
  stop: "/img/icons/stop.png",
  sync: "/img/icons/sync.png",
  thumbsup: "/img/icons/thumbsup.png",
  time: "/img/icons/time.png",
  watt: "/img/icons/watt.png",
  winner: "/img/icons/winner.png",
};

export const Icon = ({ src, size, style, onClick, disabled }) => {
  return (
    <img
      onClick={onClick}
      src={src}
      style={{
        width: size,
        height: size,
        objectFit: "contain",
        filter: disabled ? "grayscale(100%)" : undefined,
        opacity: disabled ? 0.5 : 1,
        cursor: onClick ? "pointer" : undefined,
        ...style,
      }}
      alt={""}
    />
  );
};
