import { makeStyles } from "@material-ui/core";
import React, { useRef } from "react";
import {
  loadWorkout,
  startWorkout,
  stopWorkout,
} from "../../../Reducers/Workout/Actions";
import { useDispatch, useSelector } from "react-redux";
import Theme from "../../../Core/Theme";
import {
  sendWorkout,
  sendWorkoutStart,
  sendWorkoutStop,
} from "../../../Reducers/Socket/Actions";
import Icons, { Icon } from "../../../Core/Icons";
import Button from "../Button";

const useStyles = makeStyles({
  workoutLoader: {
    margin: 16,
    display: "flex",
    flexDirection: "row",
  },
  title: {
    fontFamily: Theme.font2P,
    fontSize: 12,
    marginTop: 0,
  },
});

export const WorkoutLoader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { workoutLoaded, workoutStarted } = useSelector(
    (state) => state.Workout
  );
  const fileInputRef = useRef();
  const { currentGroupUuid } = useSelector((state) => state.Socket);

  const onStartWorkout = () => {
    dispatch(startWorkout());
    dispatch(sendWorkoutStart());
  };

  const onPauseWorkout = () => {
    dispatch(stopWorkout());
    dispatch(sendWorkoutStop());
  };

  const onOpenFile = () => {
    fileInputRef.current.click();
  };

  const onSync = () => {
    dispatch(sendWorkout());
  };

  const onChangeFile = (e) => {
    const files = e.target.files;
    if (files.length === 1) {
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        console.log("file loaded");
        dispatch(loadWorkout(event.target.result));
      });
      reader.readAsText(files[0]);
    }
  };

  return (
    <div className={classes.workoutLoader}>
      <input
        ref={fileInputRef}
        type={"file"}
        style={{ display: "none" }}
        onChange={onChangeFile}
      />
      {workoutLoaded && (
        <div>
          {!workoutStarted && (
            <Icon src={Icons.play} size={24} onClick={onStartWorkout} />
          )}
          {workoutStarted && (
            <Icon src={Icons.pause} size={24} onClick={onPauseWorkout} />
          )}
        </div>
      )}

      <div style={{ flex: 1 }} />
      {workoutLoaded && (
        <>
          {currentGroupUuid && (
            <Icon src={Icons.sync} onClick={onSync} size={24} />
          )}
          {!currentGroupUuid && (
            <Icon src={Icons.sync} disabled={true} size={24} />
          )}
        </>
      )}
      <Button onClick={onOpenFile} />
    </div>
  );
};
