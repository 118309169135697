import { useSelector } from "react-redux";
import React from "react";
import { makeStyles } from "@material-ui/core";
import useDimensions from "react-use-dimensions";
import Theme from "../../../Core/Theme";

const useStyles = makeStyles({
  histogram: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: 0,
    marginBottom: 16,
    height: 80,
    width: "100%",
    overflowX: "hidden",
  },
  bar: {
    width: 4,
    marginRight: 1,
  },
});

const zoneColors = Theme.zoneColors;

export const Histogram = ({}) => {
  const classes = useStyles();
  const { powerHistory, heartrateHistory } = useSelector(
    (state) => state.Bluetooth
  );
  const [ref, { x, y, width }] = useDimensions();
  console.log("histogram width " + width);
  const maxBars = Math.floor(width / 5);

  // we create a combined map with the timestamp as key to sync the values of both histories on the time axis
  const syncedHistory = {};
  powerHistory.forEach((ph) => {
    syncedHistory[ph.time] = { power: ph };
  });
  heartrateHistory.forEach((hh) => {
    if (syncedHistory[hh.time]) {
      syncedHistory[hh.time].heartrate = hh;
    }
  });

  // then we create an array from this map
  const syncedHistoryArr = [];
  Object.keys(syncedHistory).forEach((key) => {
    syncedHistoryArr.push(syncedHistory[key]);
  });
  let bars;
  let points;
  // and not distribute the values to our lists of bars and points
  if (syncedHistoryArr.length > maxBars) {
    bars = syncedHistoryArr
      .slice(syncedHistoryArr.length - maxBars, syncedHistoryArr.length - 1)
      .map((sync) => sync.power);
    points = syncedHistoryArr
      .slice(syncedHistoryArr.length - maxBars, syncedHistoryArr.length - 1)
      .map((sync) => sync.heartrate);
  } else {
    bars = syncedHistoryArr.map((sync) => sync.power);
    points = syncedHistoryArr.map((sync) => sync.heartrate);
  }

  return (
    <div style={{ position: "relative" }}>
      <div className={classes.histogram} ref={ref}>
        {bars &&
          bars.map((ph) =>
            ph ? (
              <div
                style={{
                  height: Math.round(ph.powerAvg / 5),
                  backgroundColor: zoneColors[ph.zone],
                }}
                className={classes.bar}
              />
            ) : null
          )}
      </div>
      <div style={{ position: "absolute", top: 0 }}>
        {points &&
          points.map((hr, i) =>
            hr ? (
              <div
                style={{
                  position: "absolute",
                  left: i * 5,
                  top: 70 - Number(hr.heartrate) / 3,
                  width: 2,
                  height: 2,
                  backgroundColor: "#333",
                }}
              />
            ) : null
          )}
      </div>
    </div>
  );
};
