import { RESET_TRACK, SET_CURRENT_POSITION, SET_TRACK } from "./Actions";

export const initialState = {
  elevationGain: 0,
  elevationLoss: 0,
  distance: 0,
  track: [],
  lastPointIndex: 0,
  trackLoaded: false,
  currentElevation: 0,
  incline: 0,
  inclineDegrees: 0,
  inclinePercent: 0,
  heightProfile: [],
  tcxPoints: [],
};

const prepareTrack = (track, maxSegments, normalizedHeight) => {
  let maxElevation = -9999;
  let minElevation = 9999;
  let totalDistance = 0;

  track.forEach((pt) => {
    totalDistance += pt.distance;
  });

  let cleanedTrack = [];
  if (track.length > maxSegments) {
    // todo: combine segments
    const combineFactor = Math.ceil(track.length / maxSegments);
    console.log("track length " + track.length);
    console.log("combine by " + combineFactor);
    let tempPt;
    let cleanDist = totalDistance / maxSegments;
    let totalDist = 0;
    track.forEach((pt) => {
      if (tempPt) {
        tempPt.distance += Number(pt.distance);
        tempPt.totalDistance = Number(totalDist) + Number(pt.distance);
        tempPt.elevation = pt.elevation;
        if (tempPt.distance > cleanDist) {
          cleanedTrack.push({ ...tempPt });
          totalDist += Number(tempPt.distance);
          tempPt = undefined;
        }
      } else {
        if (pt.distance > cleanDist) {
          console.log("add to track " + pt.distance);
          cleanedTrack.push({
            ...pt,
            totalDistance: Number(totalDist) + Number(pt.distance),
          });
          totalDist += Number(pt.distance);
        } else {
          tempPt = { ...pt };
        }
      }
    });
    console.log("new length: " + cleanedTrack.length);
  } else {
    let totalDist = 0;
    cleanedTrack = track.map((pt) => {
      const tempPt = { ...pt };
      tempPt.totalDistance = Number(totalDist) + Number(pt.distance);
      totalDist += Number(pt.distance);
      return tempPt;
    });
  }

  cleanedTrack.forEach((pt) => {
    if (maxElevation < pt.elevation) {
      maxElevation = pt.elevation;
    }
    if (minElevation > pt.elevation) {
      minElevation = pt.elevation;
    }
  });
  const elevationDifference = maxElevation - minElevation;
  const maxScaleFactor = normalizedHeight / elevationDifference;

  const distanceStep = totalDistance / maxSegments;
  console.log("distance step 1px = " + distanceStep);
  const scaleToDistance =
    (normalizedHeight / distanceStep / elevationDifference) * 50;

  const scaleFactor = Math.min(maxScaleFactor, scaleToDistance);

  console.log("difference " + elevationDifference);
  console.log(
    "scale " + maxScaleFactor + " // " + scaleToDistance + " // " + scaleFactor
  );
  const points = cleanedTrack.map((pt) => ({
    height: Math.round((pt.elevation - minElevation) * scaleFactor),
    elevation: pt.elevation,
    totalDistance: pt.totalDistance === undefined ? 0 : pt.totalDistance,
  }));

  console.log(points);

  return {
    maxElevation,
    minElevation,
    elevationDifference,
    points,
  };
};

export default function TrackReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_TRACK: {
      return { ...initialState };
    }
    case SET_TRACK:
      return {
        ...state,
        track: action.track,
        trackLoaded: true,
        distance: action.totalDistance,
        elevationGain: action.totalElevationGain,
        elevationLoss: action.totalElevationLoss,
        currentElevation:
          action.track.length > 0 ? action.track[0].elevation : 0,
        heightProfile: prepareTrack(action.track, 10000, 100),
      };

    case SET_CURRENT_POSITION:
      const { meters } = action;
      let totalDistance = 0;
      let usePoint;
      let pointIndex;
      console.log("track length " + state.track.length);
      console.log("check position for " + meters);
      state.track.forEach((pt, ix) => {
        const checkDistance = totalDistance + pt.distance;
        if (checkDistance < meters || !usePoint) {
          usePoint = pt;
          pointIndex = ix;
        }
        totalDistance += pt.distance;
      });

      console.log("use point at " + pointIndex);
      console.log(usePoint);
      let inclineDegrees = 0;
      let incline = 0;
      let inclinePercent = 0;
      let nextPoint;
      if (pointIndex < state.track.length - 1) {
        nextPoint = state.track[pointIndex + 1];
        const dist = usePoint.distance;
        const elevationChange = nextPoint.elevation - usePoint.elevation;
        console.log("elevation change: " + elevationChange);
        console.log("distance " + dist);
        // incline = sin alpha = dist / elevationChange => alpha =
        if (elevationChange !== 0) {
          incline = Math.asin(elevationChange / dist);
          inclineDegrees = (incline * 180) / Math.PI;
          inclinePercent = (elevationChange / dist) * 100;
        }
      }
      let next;
      if (pointIndex < state.track.length - 2) {
        const secondNextPoint = state.track[pointIndex + 2];
        const dist = nextPoint.distance;
        const elevationChange = secondNextPoint.elevation - nextPoint.elevation;
        console.log("next elevation change: " + elevationChange);
        console.log("next distance " + dist);
        next = {
          elevation: secondNextPoint.elevation,
          inclineDegrees: 0,
          inclinePercent: 0,
        };
        // incline = sin alpha = dist / elevationChange => alpha =
        if (elevationChange !== 0) {
          next.incline = Math.asin(elevationChange / dist);
          next.inclineDegrees = (incline * 180) / Math.PI;
          next.inclinePercent = (elevationChange / dist) * 100;
        }
      }

      let heightProfileIndex = 0;
      state.heightProfile?.points?.forEach((pt, ix) => {
        if (pt.totalDistance < meters) {
          heightProfileIndex = ix;
        }
      });
      console.log("height profile index " + heightProfileIndex);

      let tcxPoints = [...state.tcxPoints];
      if (pointIndex > state.lastPointIndex) {
        const timestampMillis = Date.now();
        tcxPoints.push({
          ...state.track[pointIndex],
          timestamp: Math.floor(timestampMillis / 1000),
        });
      }

      return {
        ...state,
        currentElevation: usePoint?.elevation,
        next,
        inclineDegrees,
        incline,
        inclinePercent,
        heightProfileIndex,
        lastPointIndex: pointIndex,
        tcxPoints,
      };

    default:
      return state;
  }
}
