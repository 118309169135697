export const formatTime = (sec) => {
  const totalSeconds = Math.round(sec);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds - minutes * 60;
  const time =
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds);
  return time;
};

export const formatDistance = (distanceRaw) => {
  return Math.round(distanceRaw / 10) / 100;
};
