import { combineReducers } from "redux";

import Bluetooth from "./Bluetooth";
import Player from "./Player";
import Socket from "./Socket";
import Track from "./Track";
import Workout from "./Workout";

export default combineReducers({
  Bluetooth,
  Player,
  Socket,
  Track,
  Workout,
});
