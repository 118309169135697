import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { Button, Card, makeStyles, Paper } from "@material-ui/core";
import {
  clearPairedDevices,
  detectCadence,
  detectDevices,
  detectHeartRate,
  detectPower,
  setFitnessMachineTargetPower,
} from "../../../Reducers/Bluetooth/Actions";

const useStyles = makeStyles({
  sensorStatus: {
    padding: 16,
  },
  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  title: {
    textAlign: "left",
    margin: 0,
    width: 65,
  },
  statusOnline: {
    display: "inline-block",
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: "#00FF00",
    marginTop: 4,
    marginLeft: 8,
  },
  statusOffline: {
    cursor: "pointer",
    display: "inline-block",
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: "#CCCCCC",
    marginTop: 4,
    marginLeft: 8,
  },
});

const BluetoothConnection = () => {
  const dispatch = useDispatch();
  const onClickDetect = () => {
    // todo: can this be made more elegant? no dispatch in the function call?
    detectDevices(dispatch);
  };

  const onClickClearPaired = () => {
    dispatch(clearPairedDevices());
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        onClick={onClickDetect}
        variant={"contained"}
        style={{ marginBottom: 4 }}
      >
        Reconnect
      </Button>
      <Button onClick={onClickClearPaired} variant={"contained"}>
        Remove paired
      </Button>
    </div>
  );
};

export const StatusIndicator = ({ online, onClick }) => {
  const classes = useStyles();
  return (
    <div
      className={online ? classes.statusOnline : classes.statusOffline}
      onClick={onClick}
    />
  );
};

export const SensorStatus = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [minimized, setMinimized] = useState(true);
  const [power, setPower] = useState(150);
  const { hasPower, hasCadence, hasHeartrate, hasFitnessMachine } = useSelector(
    (state) => state.Bluetooth
  );

  const onConnectPower = () => {
    detectPower(dispatch);
  };
  const onConnectCadence = () => {
    detectCadence(dispatch);
  };
  const onConnectHeartrate = () => {
    detectHeartRate(dispatch);
  };
  const onSetPower = () => {
    dispatch(setFitnessMachineTargetPower(power));
  };

  if (minimized) {
    return <div onClick={() => setMinimized(false)}>Bluetooth Settings</div>;
  } else {
    return (
      <div className={classes.sensorStatus}>
        <div onClick={() => setMinimized(true)}>hide</div>
        <div className={classes.row}>
          <p className={classes.title}>Power</p>
          <StatusIndicator online={hasPower} onClick={onConnectPower} />
        </div>
        <div className={classes.row}>
          <p className={classes.title}>Cadence</p>
          <StatusIndicator online={hasCadence} onClick={onConnectCadence} />
        </div>
        <div className={classes.row}>
          <p className={classes.title}>Heartrate</p>
          <StatusIndicator online={hasHeartrate} onClick={onConnectHeartrate} />
        </div>
        {hasFitnessMachine && (
          <div className={classes.row}>
            <p className={classes.title}>Power Control</p>
            <input
              value={power}
              type={"number"}
              onChange={(e) => setPower(e.target.value)}
              placeholder={"target power"}
            />
            <button onClick={onSetPower}>Set</button>
          </div>
        )}
        <BluetoothConnection />
      </div>
    );
  }
};
