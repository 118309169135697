import { setUserFtp, setUserMaxHr, setUserWeight } from "../Bluetooth/Actions";

export const SET_NAME = "SET_PLAYER_NAME";
export const SET_UUID = "SET_UUID";
export const SET_WEIGHT = "SET_WEIGHT";
export const SET_FTP = "SET_FTP";
export const SET_MAX_HR = "SET_MAX_HR";
export const SET_RIDER_GRAPHICS = "SET_RIDER_GRAPHICS";

export const setUuid = (uuid) => ({
  type: SET_UUID,
  uuid,
});

export const setName = (name) => ({
  type: SET_NAME,
  name,
});

export const setWeight = (weight) => (dispatch) => {
  dispatch(setUserWeight(weight));
  dispatch({
    type: SET_WEIGHT,
    weight,
  });
};

export const setFtp = (ftp) => (dispatch) => {
  dispatch(setUserFtp(ftp));
  dispatch({
    type: SET_FTP,
    ftp,
  });
};

export const setMaxHr = (maxHr) => (dispatch) => {
  dispatch(setUserMaxHr(maxHr));
  dispatch({
    type: SET_MAX_HR,
    maxHr,
  });
};

export const setGraphicsVariant = (variant) => ({
  type: SET_RIDER_GRAPHICS,
  variant,
});

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
