import { Workout } from "../Workout";
import Track from "../Track";
import { makeStyles } from "@material-ui/core";
import Theme from "../../../Core/Theme";
import React, { useState } from "react";

const tabBase = {
  userSelect: "none",
  cursor: "pointer",
  padding: 12,
};

const useStyles = makeStyles({
  box: {
    width: 342,
    backgroundColor: Theme.colors.white,
    fontFamily: Theme.font2P,
    display: "flex",
    flexDirection: "column",
  },
  compactBox: {
    backgroundColor: Theme.colors.white,
    fontFamily: Theme.font2P,
    display: "flex",
    flexDirection: "column",
    width: 300,
  },
  tabBar: {
    userSelect: "none",
    display: "flex",
    flexDirection: "row",
  },
  tab: {
    ...tabBase,
    color: "#999999",
  },
  tabActive: {
    ...tabBase,
    color: "#000000",
    backgroundColor: "#CCCCCC",
  },
  row: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    "& p": {
      position: "absolute",
      width: 320,
      flex: 1,
      fontFamily: Theme.font2P,
      fontSize: 14,
      marginTop: 16,
      textAlign: "right",
    },
  },
  workoutSteps: {
    flex: 1,
    maxHeight: 400,
    overflowY: "scroll",
    scrollbars: "none",
  },
  background: {
    position: "relative",
    left: 0,
    top: 0,
    height: 44,
    width: 44,
  },
});

const WorkoutAndTrack = ({ compact }) => {
  const classes = useStyles();
  const [tab, setTab] = useState();

  return (
    <div className={compact ? classes.compactBox : classes.box}>
      <div className={classes.tabBar}>
        <div
          className={tab === "workout" ? classes.tabActive : classes.tab}
          onClick={() => setTab("workout")}
        >
          Workout
        </div>
        <div
          className={tab === "gpx" ? classes.tabActive : classes.tab}
          onClick={() => setTab("gpx")}
        >
          GPX
        </div>
      </div>
      {tab === "workout" ? <Workout /> : <Track />}
    </div>
  );
};

export default WorkoutAndTrack;
