import Icons, { Icon } from "../../../Core/Icons";
import React from "react";

const Button = ({ onClick, style }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        border: "2px solid",
        borderRadius: 8,
        padding: 4,
        width: 110,
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
      onClick={onClick}
    >
      <Icon src={Icons.floppy} size={24} />
      <h4 style={{ padding: 0, margin: 5 }}>Load</h4>
    </div>
  );
};

export default Button;
