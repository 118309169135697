import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadTrack, resetTrack } from "../../../Reducers/Track/Actions";
import Button from "../Button";
import { makeStyles } from "@material-ui/core";
import Theme from "../../../Core/Theme";

const useStyles = makeStyles({
  box: {
    fontFamily: Theme.font2P,
  },
  overlayBox: {
    fontFamily: Theme.font2P,
    display: "flex",
    flexDirection: "row",
    color: "#fff",
    opacity: 0.8,
    maxWidth: "100%",
  },
  histogram: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: 0,
    marginBottom: 0,
    height: 100,
  },
  bar: {
    width: 1,
  },
});

const HeightProfile = ({ overlay, scaled }) => {
  const classes = useStyles();
  const { heightProfile, heightProfileIndex, track } = useSelector(
    (state) => state.Track
  );
  const [info, setInfo] = useState("");
  const [highlightIndex, setHighlightIndex] = useState(undefined);
  const [trackScale, setTrackScale] = useState(1);
  const [histogramOffset, setHistogramOffset] = useState(0);

  const wrapperRef = useRef(null);

  const setInfoFromHeightProfile = () => {
    console.log("set info from height profile " + heightProfileIndex);
    if (heightProfileIndex !== undefined && heightProfile) {
      const pt = heightProfile.points[heightProfileIndex];
      setInfo(
        pt.elevation + "m / " + Math.round(pt.totalDistance / 100) / 10 + "km"
      );
    }
  };

  useEffect(() => {
    setInfoFromHeightProfile();
    if (wrapperRef) {
      const width = wrapperRef.current.offsetWidth;
      if (width < track.length) {
        const maxMove = track.length - width;
        const rightOffset = 100;
        let move = 0;
        if (heightProfileIndex > width - rightOffset) {
          move = Math.min(maxMove, heightProfileIndex - (width - rightOffset));
        }
        setHistogramOffset(-move);
        console.log(
          "track is bigger -> move max " + maxMove + " / move " + move
        );
      }
    }
  }, [heightProfileIndex]);

  useEffect(() => {
    if (wrapperRef && scaled) {
      const width = wrapperRef.current.offsetWidth;
      if (width < track.length) {
        const scale = width / track.length;
        console.log("track is bigger -> scale track " + scale);
        setTrackScale(scale);
      }
    } else {
      setTrackScale(1);
    }
  }, [wrapperRef?.current?.offsetWidth, scaled]);

  return (
    <div
      style={
        overlay
          ? { flex: 1, overflowX: "hidden", height: 120, opacity: 0.8 }
          : undefined
      }
    >
      <div
        ref={wrapperRef}
        style={{
          position: "relative",
          paddingBottom: 0,
          paddingTop: 20,
          maxWidth: "100%",
          overflowX: "hidden",
        }}
        onMouseLeave={() => {
          setInfoFromHeightProfile();
          setHighlightIndex(undefined);
        }}
      >
        <div
          className={classes.histogram}
          style={{
            marginLeft: histogramOffset,
            transform: "scaleX(" + trackScale + ")",
            transformOrigin: "left",
          }}
        >
          {heightProfile.points &&
            heightProfile.points.map((pt, ix) =>
              pt ? (
                <div
                  i={"bar-" + ix}
                  style={{
                    minWidth: 1,
                    height: pt.height,
                    backgroundColor:
                      ix === highlightIndex
                        ? "#ee3333"
                        : ix < heightProfileIndex
                        ? "#33dd33"
                        : "#333333",
                  }}
                  className={classes.bar}
                  onMouseEnter={() => {
                    setHighlightIndex(ix);
                    setInfo(
                      pt.elevation +
                        "m / " +
                        Math.round(pt.totalDistance / 100) / 10 +
                        "km"
                    );
                  }}
                />
              ) : null
            )}
        </div>
      </div>
      <div style={{ position: "absolute", bottom: 0 }}>
        <p
          style={{
            textAlign: "center",
            margin: 2,
            color: "#EEE",
            textShadow: Theme.textShadow,
          }}
        >
          {info}
        </p>
      </div>
    </div>
  );
};

const TrackLoader = ({}) => {
  const fileInputRef = useRef();
  const dispatch = useDispatch();

  const onChangeFile = (e) => {
    const files = e.target.files;
    if (files.length === 1) {
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        console.log("track file loaded");
        dispatch(loadTrack(event.target.result));
      });
      reader.readAsText(files[0]);
    }
  };
  const onOpenFile = () => {
    fileInputRef.current.click();
  };

  return (
    <div style={{ padding: 16, display: "flex" }}>
      <input
        ref={fileInputRef}
        type={"file"}
        accept={".gpx"}
        style={{ display: "none" }}
        onChange={onChangeFile}
      />
      <div style={{ flex: 1 }} />
      <Button onClick={onOpenFile} style={{ height: 32 }} />
    </div>
  );
};

const Track = ({ overlay }) => {
  const dispatch = useDispatch();
  const {
    trackLoaded,
    distance,
    elevationGain,
    elevationLoss,
    currentElevation,
    inclinePercent,
    next,
  } = useSelector((state) => state.Track);
  const [minimized, setMinimized] = useState(false);
  const [trackScaled, setTrackScaled] = useState(false);
  const classes = useStyles();
  console.log(next);
  return (
    <div className={overlay ? classes.overlayBox : classes.box}>
      {!trackLoaded && !minimized && (
        <h4 style={{ paddingTop: 16, paddingLeft: 4 }}>No GPX loaded</h4>
      )}
      {trackLoaded && !minimized && (
        <div
          style={{ textAlign: "left", padding: 16, fontSize: 12, width: 360 }}
        >
          <p>
            <strong>Total </strong>
            {Math.round(distance / 1000)}km
          </p>
          <p>
            Up {Math.round(elevationGain)}m / Down {Math.round(elevationLoss)}m
          </p>
          <p>
            <strong>Current </strong>
            {currentElevation}m | {Math.round(inclinePercent * 10) / 10}%
          </p>
          <p>
            <strong>Next </strong>
            {next?.elevation}m | {Math.round(next?.inclinePercent * 10) / 10}%
          </p>
        </div>
      )}

      {!trackLoaded && !minimized ? <TrackLoader loaded={trackLoaded} /> : null}
      <div style={{ marginRight: minimized ? -16 : 0, zIndex: 2 }}>
        {overlay && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setMinimized(!minimized);
            }}
          >
            {minimized ? ">" : "<"}
            {minimized && !trackLoaded && (
              <div>
                G<br />P<br />X
              </div>
            )}
          </div>
        )}
        {trackLoaded && (
          <>
            <div
              style={{
                cursor: "pointer",
                height: 24,
                alignItems: "center",
                display: "flex",
              }}
              onClick={() => {
                setTrackScaled(!trackScaled);
              }}
            >
              {trackScaled ? "+" : "-"}
            </div>
            <div
              style={{
                cursor: "pointer",
                height: 24,
                alignItems: "center",
                display: "flex",
              }}
              onClick={() => dispatch(resetTrack())}
            >
              x
            </div>
          </>
        )}
      </div>

      {trackLoaded && <HeightProfile overlay={overlay} scaled={trackScaled} />}
    </div>
  );
};

export default Track;
