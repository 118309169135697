import Theme from "../../../Core/Theme";
import React from "react";

export const Character = ({ text }) => {
  return (
    <div
      style={{
        pointerEvents: "none",
        opacity: text ? 1 : 0,
        "-webkit-transition": "opacity 0.3s ease-in-out",
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <div
        style={{
          position: "relative",
          bottom: 370,
          right: 350,
          width: 600,
          backgroundColor: Theme.colors.white,
          boxShadow: Theme.func.makeBox(Theme.colors.grey),
          zIndex: 1,
        }}
      >
        <h3
          style={{
            fontFamily: Theme.font2P,
            lineHeight: "32px",
            textAlign: "left",
            margin: 16,
            padding: 16,
            paddingRight: 180,
          }}
        >
          {text}
        </h3>
      </div>
      <div style={{ position: "absolute", zIndex: 2, bottom: 0 }}>
        <img src={"/img/chars/ulle.png"} style={{ height: 500, zIndex: 2 }} />
      </div>
    </div>
  );
};
