import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { Button, makeStyles, TextField } from "@material-ui/core";
import {
  sendCreateGroup,
  sendJoinGroup,
  sendLeaveGroup,
  sendRemoveGroup,
} from "../../../Reducers/Socket/Actions";
import Theme from "../../../Core/Theme";
import { formatDistance } from "../../../Core/utils";

const useStyles = makeStyles({
  lobby: {
    backgroundColor: "#ffffff",
    padding: 16,
    "& h4": {
      marginTop: 0,
      marginBottom: 8,
    },
    textAlign: "left",
    height: 200,
    display: "flex",
    flexDirection: "row",
    fontFamily: Theme.font2P,
  },
  button: {
    cursor: "pointer",
    color: Theme.colors.grey,
  },
  buttonActive: {
    cursor: "pointer",
  },
  playerList: {
    display: "flex",
    flexDirection: "row",
  },
  playerRow: {},
  connected: {
    marginLeft: 12,
    marginTop: 4,
    width: 12,
    height: 12,
    borderRadius: 16,
    backgroundColor: "#00FF00",
  },
  disconnected: {
    marginLeft: 12,
    marginTop: 4,
    width: 12,
    height: 12,
    borderRadius: 16,
    backgroundColor: "#AAAAAA",
  },
  groupList: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
  },
  groupTile: {
    position: "relative",
    fontFamily: Theme.font2P,
    marginRight: 32,
    "& h4": {
      margin: 8,
    },
    "& p": {
      fontSize: 14,
    },
  },
  playerTile: {
    fontFamily: Theme.font2P,
    marginRight: 32,
    "& h4": {
      margin: 8,
    },
  },
  joinButton: {
    border: "2px solid #000",
    padding: 8,
    textAlign: "center",
    fontSize: 14,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Theme.colors.lightGrey,
    },
  },
});

const PlayerTile = ({ player, lobby }) => {
  const classes = useStyles();
  return (
    <div className={classes.playerTile}>
      <h4>{player.name}</h4>
      {!lobby && (
        <>
          <h4>{player.currentPower}W</h4>
          <h4>{formatDistance(player.ridingDistance)} km</h4>
        </>
      )}
    </div>
  );
};

const GroupTile = ({ name, players, onJoin, showDelete, onDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.groupTile}>
      <h4>{name}</h4>
      <p>{players} Riders</p>
      <p className={classes.joinButton} onClick={onJoin}>
        Join Group
      </p>
      {showDelete && (
        <img
          src={"/img/pixel-icons/red-cross.svg"}
          alt={"Remove"}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: 16,
            height: 16,
            cursor: "pointer",
          }}
          onClick={onDelete}
        />
      )}
    </div>
  );
};

export const Lobby = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    connected,
    connecting,
    players,
    playerList,
    groupList,
    currentGroupUuid,
  } = useSelector((state) => state.Socket);
  const { uuid } = useSelector((state) => state.Player);

  const [showGroups, setShowGroups] = useState(true);
  const [showPlayers, setShowPlayers] = useState(false);

  const [showGroupAdd, setShowGroupAdd] = useState(false);
  const [groupName, setGroupName] = useState("");

  const currentGroup = currentGroupUuid
    ? groupList.find((g) => g.uuid === currentGroupUuid)
    : undefined;

  const onCreateGroup = () => {
    dispatch(sendCreateGroup(groupName));
    setShowGroupAdd(false);
  };

  const onJoin = (groupUuid) => {
    dispatch(sendJoinGroup(groupUuid));
  };

  const onLeave = (groupUuid) => {
    dispatch(sendLeaveGroup(groupUuid));
  };

  const onBackToLobby = () => {
    dispatch(sendLeaveGroup(currentGroupUuid));
  };

  const onRemoveGroup = (groupUuid) => {
    dispatch(sendRemoveGroup(groupUuid));
  };

  return (
    <div className={classes.lobby}>
      <div style={{ marginRight: 32 }}>
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: 16 }}
        >
          <h4>{currentGroup ? currentGroup.name : "Lobby"}</h4>
          {connected ? (
            <div className={classes.connected} />
          ) : (
            <div className={classes.disconnected} />
          )}
        </div>
        {!connected && <p>offline</p>}
        {connected && (
          <>
            {currentGroup && (
              <div>
                <p className={classes.joinButton} onClick={onBackToLobby}>
                  Leave Group
                </p>
              </div>
            )}
            {!currentGroup && (
              <>
                <h4
                  className={
                    showPlayers ? classes.buttonActive : classes.button
                  }
                  onClick={() => {
                    setShowPlayers(true);
                    setShowGroups(false);
                  }}
                >
                  Players (
                  {playerList && playerList.length ? playerList.length : 0})
                </h4>
                <h4
                  className={showGroups ? classes.buttonActive : classes.button}
                  onClick={() => {
                    setShowPlayers(false);
                    setShowGroups(true);
                  }}
                >
                  Groups ({groupList && groupList.length ? groupList.length : 0}
                  )
                </h4>
                {showGroups && !showGroupAdd && (
                  <p
                    className={classes.joinButton}
                    onClick={() => setShowGroupAdd(true)}
                  >
                    Add Group
                  </p>
                )}
              </>
            )}
          </>
        )}
      </div>
      {currentGroup && (
        <div className={classes.playerList}>
          {/* players with performance data */}
          {players && players.map((player) => <PlayerTile player={player} />)}
        </div>
      )}
      {!currentGroup && showGroups && (
        <div className={classes.groupList}>
          {showGroupAdd && (
            <div>
              <TextField
                placeholder={"Group Name"}
                onChange={(e) => setGroupName(e.target.value)}
                style={{ width: 180 }}
              />
              <Button onClick={onCreateGroup}>Create</Button>
              <Button onClick={() => setShowGroupAdd(false)}>Cancel</Button>
            </div>
          )}
          {groupList &&
            groupList.map((group) => (
              <GroupTile
                onJoin={() => onJoin(group.uuid)}
                onDelete={() => onRemoveGroup(group.uuid)}
                name={group.name}
                players={Object.keys(group.players).length}
                showDelete={
                  group.uuid !== currentGroupUuid &&
                  Object.keys(group.players).length === 0 &&
                  group.creatorUuid === uuid
                }
              />
            ))}
        </div>
      )}
      {!currentGroup && showPlayers && (
        <div className={classes.groupList}>
          {playerList &&
            playerList.map((player) => (
              <PlayerTile player={player} lobby={true} />
            ))}
        </div>
      )}
    </div>
  );
};
