import {
  CONNECTING,
  CONNECTION_ERROR,
  CONNECTION_ESTABLISHED,
  JOIN_GROUP,
  RECEIVE_GROUP_LIST,
  RECEIVE_PLAYER_DATA,
  RECEIVE_PLAYER_LIST,
  RECEIVE_STRAVA_AUTH_COMPLETE,
  RESET_PLAYER_DATA,
} from "./Actions";

export const initialState = {
  playerList: [],
  players: [],
  groupList: [],
  currentGroupUuid: undefined,
  connected: false,
  connecting: false,
  stravaAuthComplete: false,
  stravaAccessToken: undefined,
};

export default function SocketReceiver(state = initialState, action = {}) {
  switch (action.type) {
    case CONNECTING:
      return { ...state, connected: false };

    case CONNECTION_ESTABLISHED:
      return { ...state, connected: true };

    case CONNECTION_ERROR:
      return { ...state, connected: false };

    case RECEIVE_PLAYER_LIST:
      return { ...state, playerList: action.playerList };

    case RESET_PLAYER_DATA:
      return { ...state, players: [] };
      break;

    case RECEIVE_PLAYER_DATA: {
      let newPlayers = state.players.filter(
        (player) => player.uuid !== action.playerData.uuid
      );
      action.playerData.lastUpdate = Date.now() / 1000;
      newPlayers.push(action.playerData);

      console.log("receive player data " + action.playerData.name);

      // remove players after 10 seconds inactivity
      newPlayers = newPlayers.filter(
        (p) => p.lastUpdate >= Date.now() / 1000 - 10
      );
      newPlayers.forEach((p) => {
        const check = Date.now() / 1000 - 10;
        console.log("last update " + p.lastUpdate + " " + check);
      });
      console.log("players length " + newPlayers.length);

      return {
        ...state,
        players: newPlayers,
      };
    }

    case RECEIVE_GROUP_LIST: {
      return { ...state, groupList: action.groupList };
    }

    case JOIN_GROUP: {
      return { ...state, currentGroupUuid: action.groupUuid };
    }

    case RECEIVE_STRAVA_AUTH_COMPLETE: {
      return {
        ...state,
        stravaAuthComplete: true,
        stravaAccessToken: action.token,
      };
    }

    default:
      return state;
  }
}
