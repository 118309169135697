import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Button, makeStyles, TextField } from "@material-ui/core";
import useDimensions from "react-use-dimensions";
import Theme from "../../../Core/Theme";
import {
  receiveCadenceDebug,
  receiveHeartrate,
  receivePower,
  receivePowerDebug,
} from "../../../Reducers/Bluetooth/Actions";

const useStyles = makeStyles({
  debug: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: 16,
  },
  bar: {
    width: 4,
    marginRight: 1,
  },
});

export const DebugValues = () => {
  const classes = useStyles();
  const [power, setPower] = useState(150);
  const [cadence, setCadence] = useState(80);
  const [heartrate, setHeartrate] = useState(140);
  const [running, setRunning] = useState(false);
  const [slope, setSlope] = useState(-5);
  const dispatch = useDispatch();
  const intervalRef = useRef();

  useEffect(() => {
    clearInterval(intervalRef.current);
    if (running) {
      intervalRef.current = setInterval(() => {
        console.log(
          "set debug values " + power + " " + cadence + " " + heartrate
        );
        dispatch(receivePower(power));
        dispatch(receiveHeartrate(heartrate));
        dispatch(receiveCadenceDebug(cadence));
      }, 1000);
    } else {
      console.log("clear interval");
      dispatch(receivePower(0));
    }
  }, [running, power, heartrate, cadence]);

  const processPower = () => {
    dispatch(receivePowerDebug(power, slope));
  };

  return (
    <div className={classes.debug}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          type={"number"}
          label={"PWR"}
          value={power}
          onChange={(e) => setPower(e.target.value)}
          style={{ width: 70 }}
        />
        <TextField
          type={"number"}
          label={"CAD"}
          value={cadence}
          onChange={(e) => setCadence(e.target.value)}
          style={{ width: 70 }}
        />
        <TextField
          type={"number"}
          label={"HR"}
          value={heartrate}
          onChange={(e) => setHeartrate(e.target.value)}
          style={{ width: 70 }}
        />
        <TextField
          type={"number"}
          label={"Slope"}
          value={slope}
          onChange={(e) => setSlope(e.target.value)}
          style={{ width: 70 }}
        />
        <Button onClick={() => setRunning(!running)}>
          {running ? "Stop" : "Start"}
        </Button>
        <Button onClick={() => processPower()}>Process Power/Slope</Button>
      </div>
    </div>
  );
};
