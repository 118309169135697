import React, { useEffect, useRef, useState } from "react";
import Konva from "konva";
import { Layer, Stage } from "react-konva";
import Theme from "../../../Core/Theme";
import Graphics from "../../../Core/Graphics";

const colors = {
  sky1: "#79cbe4",
  sky2: "#5bb2cd",
  grass: "#6cc130",
};

const riders = {};
const riderTexts = {};
const animations = {
  idle: [
    // x, y, width, height (4 frames)
    0,
    0,
    368,
    332,

    375,
    0,
    736,
    332,

    0,
    332,
    368,
    332,

    375,
    332,
    736,
    332,

    0,
    664,
    368,
    332,

    375,
    664,
    736,
    332,
  ],
};
export const RidingGraphics = ({ player, players, slopeDegrees }) => {
  const width = window.innerWidth;

  const layerRef = useRef();
  const bgLayerRef = useRef();
  const riderRef = useRef();
  const animRefBg = useRef();
  const animRef = useRef();
  const layer = layerRef.current;
  const bgLayer = bgLayerRef.current;
  const rider = riderRef.current;

  const frameRateRef = useRef();

  const frameRate = Math.round(player.currentSpeed);
  frameRateRef.current = frameRate;

  const bgGroupRef = useRef();
  const bg2GroupRef = useRef();
  const bg3GroupRef = useRef();
  const fgGroupRef = useRef();
  const textGroupRef = useRef();

  const height = window.innerHeight - 500; // 80 text height, 200 lobby height, 300 top height
  const bottomOffset = 80;
  const foregroundHeight = 120;

  useEffect(() => {
    bgGroupRef.current = new Konva.Group({});
    bg2GroupRef.current = new Konva.Group({});
    bg3GroupRef.current = new Konva.Group({});

    textGroupRef.current = new Konva.Group({
      x: width / 2,
    });
    fgGroupRef.current = new Konva.Group({
      x: width / 2,
      y: height - bottomOffset - foregroundHeight,
      scaleX: 1,
      scaleY: 1,
    });
    const backgroundGroup = bgGroupRef.current;
    const background2Group = bg2GroupRef.current;
    const background3Group = bg3GroupRef.current;

    const foregroundGroup = fgGroupRef.current;

    if (layer) {
      bgLayer.add(background3Group);
      bgLayer.add(background2Group);

      layer.add(backgroundGroup);
      layer.add(foregroundGroup);
      bgLayer.add(textGroupRef.current);

      // add sky
      const sky1 = new Konva.Rect({
        fill: colors.sky1,
        width: width * 2,
        height: height - bottomOffset,
      });
      background3Group.add(sky1);

      // add background grass
      const grassBg = new Konva.Rect({
        fill: colors.grass,
        width: width * 2,
        y: height - 128 - bottomOffset,
        height: 128,
      });
      background3Group.add(grassBg);

      const grass = new Konva.Rect({
        fill: colors.grass,
        width: width * 2,
        y: height - bottomOffset - 64,
        height: 64,
      });
      backgroundGroup.add(grass);

      // add street
      const street = new Konva.Rect({
        zIndex: 0,
        width: width * 2,
        height: 32,
        y: height - bottomOffset - 32,
        fill: "#777777",
      });
      backgroundGroup.add(street);
      const stripCount = Math.ceil((width * 2) / 100);

      for (let i = 0; i < stripCount; i++) {
        const strip = new Konva.Rect({
          zIndex: 10,
          x: i * 200,
          y: height - bottomOffset - 18,
          height: 3,
          width: 64,
          fill: "#FFFFFF",
        });
        backgroundGroup.add(strip);
      }

      // load trees
      new Konva.Image.fromURL("/img/tree-1.png", (treeImg) => {
        treeImg.setAttrs({ scaleX: 0.2, scaleY: 0.2 });

        const amountTrees = Math.ceil((width + 400) / 200);

        for (let i = 0; i < amountTrees; i += 2) {
          const t = treeImg.clone({
            y: height - bottomOffset - 200,
            x: 200 * i,
          });
          backgroundGroup.add(t);
        }
        layer.batchDraw();
      });
      new Konva.Image.fromURL("/img/tree-2.png", (treeImg) => {
        treeImg.setAttrs({ scaleX: 0.2, scaleY: 0.2 });

        const amountTrees = Math.ceil((width + 400) / 200);

        for (let i = 1; i < amountTrees; i += 2) {
          const t = treeImg.clone({
            y: height - bottomOffset - 210,
            x: 30 + 200 * i,
          });
          backgroundGroup.add(t);
        }
        layer.batchDraw();
      });

      // add clouds
      new Konva.Image.fromURL("/img/cloud.png", (cloudImg) => {
        cloudImg.setAttrs({ scaleX: 1, scaleY: 1, y: 10 });
        const amountClouds = Math.ceil((width + 400) / 150);
        for (let i = 0; i < amountClouds; i++) {
          const ci = cloudImg.clone({ y: i % 2 === 0 ? 10 : 20, x: 150 * i });
          background3Group.add(ci);
        }
        bgLayer.batchDraw();
      });
      // add far mountains
      new Konva.Image.fromURL(
        "/img/backgrounds/mountain-far.png",
        (mountainImg) => {
          mountainImg.setAttrs({ scaleX: 2.5, scaleY: 2.5, y: 10 });
          const amountClouds = Math.ceil((width + 400) / 300);
          for (let i = 0; i < amountClouds; i++) {
            const mi = mountainImg.clone({
              y: height - bottomOffset - 400,
              x: 300 * i,
            });
            background3Group.add(mi);
          }
          bgLayer.batchDraw();
        }
      );

      // add mountains
      new Konva.Image.fromURL(
        "/img/backgrounds/mountains.png",
        (mountainImg) => {
          mountainImg.setAttrs({ scaleX: 3, scaleY: 3, y: 10 });
          const amount = Math.ceil((width + 1200) / 600);
          for (let i = 0; i < amount; i++) {
            const ci = mountainImg.clone({
              y: height - bottomOffset - 400,
              x: 1200 * i,
            });
            background2Group.add(ci);
          }
          bgLayer.batchDraw();
        }
      );

      animRef.current = new Konva.Animation(function (frame) {
        let x = backgroundGroup.x() - frameRateRef.current * 0.75;
        if (x <= -400) {
          x = 0;
        }
        backgroundGroup.setAttrs({ x });
      }, layer);

      animRefBg.current = new Konva.Animation(function (frame) {
        // parallax scrolling
        let x2 = background2Group.x() - frameRateRef.current * 0.2;
        if (x2 <= -1200) {
          x2 = 0;
        }
        background2Group.setAttrs({ x: x2 });

        // parallax scrolling
        let x3 = background3Group.x() - frameRateRef.current * 0.1;
        if (x3 <= -300) {
          x3 = 0;
        }
        background3Group.setAttrs({ x: x3 });
      }, bgLayer);
      // layer.setAttrs({ rotation: 10 });
    }
  }, [layer]);

  if (animRef.current) {
    if (frameRate > 0) {
      animRef.current.start();
      animRefBg.current.start();
    } else {
      animRef.current.stop();
      animRefBg.current.stop();
    }
  }

  let maxSpeed = 0;
  players.forEach((p) => {
    if (p.currentSpeed > maxSpeed) {
      maxSpeed = p.currentSpeed;
    }
  });

  if (layer) {
    let index = 0;

    const existingUuids = [];

    players
      .sort((a, b) => (a.uuid < b.uuid ? 1 : -1))
      .forEach((pl) => {
        existingUuids.push(pl.uuid);
        const xPos = maxSpeed
          ? -(width * 0.25) + ((pl.currentSpeed / maxSpeed) * width) / 2
          : 0;
        const textPos = xPos;
        // console.log("player uuid " + pl.uuid + " " + pl.name);
        // console.log("player speed " + pl.currentSpeed + " " + maxSpeed);
        // console.log("rider xpos " + xPos);
        // console.log("rider index " + index);
        if (!riders[pl.uuid]) {
          let imageObj = new Image();
          imageObj.onload = function () {
            riders[pl.uuid] = new Konva.Sprite({
              x: !isNaN(xPos) ? xPos : 0,
              y: 0,
              scaleX: 0.3,
              scaleY: 0.3,
              image: imageObj,
              animation: "idle",
              animations: animations,
              frameRate: 5,
              frameIndex: 0,
            });
            fgGroupRef.current.add(riders[pl.uuid]);
            riderTexts[pl.uuid] = new Konva.Text({
              text: pl.name,
              fontFamily: Theme.font2P,
              x: textPos,
              y: height - 60 + index * 20,
            });
            textGroupRef.current.add(riderTexts[pl.uuid]);
            layer.draw();
            bgLayer.draw();
            // riders[pl.uuid].start();
          };
          riders[pl.uuid] = "loading";
          const variant = pl.riderGraphicsVariant ? pl.riderGraphicsVariant : 0;
          const files = Graphics.riders;
          imageObj.src = files[variant];
        } else if (riders[pl.uuid] !== "loading") {
          const riderFramerate = Math.round(pl.currentSpeed);
          if (riderFramerate > 0) {
            riders[pl.uuid].start();
          } else {
            riders[pl.uuid].stop();
          }
          riders[pl.uuid].setAttrs({
            scaleX: 0.3,
            scaleY: 0.3,
            frameRate: riderFramerate,
            x: xPos,
          });
          riderTexts[pl.uuid].setAttrs({
            text: pl.name,
            x: !isNaN(textPos) ? textPos : 0,
            y: height - 60 + index * 20,
          });
          console.log("ROTATE " + slopeDegrees);
          layer.rotation(-slopeDegrees);
          layer.batchDraw();
          bgLayer.batchDraw();
        }
        index++;
      });
    Object.keys(riders).forEach((uuid) => {
      if (existingUuids.indexOf(uuid) === -1) {
        if (riders[uuid] && riders[uuid].remove) {
          riders[uuid].remove();
          delete riders[uuid];
          riderTexts[uuid].remove();
          delete riderTexts[uuid];
        }
        layer.batchDraw();
        bgLayer.batchDraw();
      }
    });
  }

  return (
    <Stage width={width} height={height} style={{ backgroundColor: "#FFFFFF" }}>
      <Layer ref={bgLayerRef} />
      <Layer ref={layerRef} />
    </Stage>
  );
};
