import "./App.css";
import { Main } from "./Screens/Main";
import { CookiesProvider } from "react-cookie";

export const log = (data) => {
  if (!window._log) {
    window._log = [];
  }
  window._log.push(data.toString());
  console.log(data);
  window.dispatchEvent(new CustomEvent("logsUpdated"));
};

function App() {
  return (
    <div className="App">
      <CookiesProvider>
        <Main />
      </CookiesProvider>
    </div>
  );
}

export default App;
