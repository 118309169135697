import moment from "moment";

export const createTcxFromPoints = (
  points,
  powerHistory,
  heartrateHistory,
  totalDistance,
  totalCalories
) => {
  const startDateStr = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  const xmlString = "<TrainingCenterDatabase></TrainingCenterDatabase>";
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "text/xml");
  const activitiesNode = xmlDoc.createElement("Activities");
  const root = xmlDoc.getElementsByTagName("TrainingCenterDatabase");
  root[0].appendChild(activitiesNode);
  const activityNode = xmlDoc.createElement("Activity");
  activityNode.setAttribute("Sport", "Biking");
  activitiesNode.appendChild(activityNode);
  const id = xmlDoc.createElement("Id");
  id.appendChild(xmlDoc.createTextNode(startDateStr));
  activityNode.appendChild(id);
  const lap = xmlDoc.createElement("Lap");
  lap.setAttribute("StartTime", startDateStr);
  activityNode.appendChild(lap);
  const totalTimeSeconds = xmlDoc.createElement("TotalTimeSeconds");
  totalTimeSeconds.appendChild(xmlDoc.createTextNode(0 + ""));
  lap.appendChild(totalTimeSeconds);
  const distanceMeters = xmlDoc.createElement("DistanceMeters");
  distanceMeters.appendChild(xmlDoc.createTextNode(totalDistance + ""));
  lap.appendChild(distanceMeters);
  const calories = xmlDoc.createElement("Calories");
  calories.appendChild(xmlDoc.createTextNode(totalCalories + ""));
  lap.appendChild(calories);
  const intensity = xmlDoc.createElement("Intensity");
  intensity.appendChild(xmlDoc.createTextNode("Active"));
  lap.appendChild(intensity);
  const triggerMethod = xmlDoc.createElement("TriggerMethod");
  triggerMethod.appendChild(xmlDoc.createTextNode("Manual"));
  lap.appendChild(triggerMethod);
  const track = xmlDoc.createElement("Track");
  lap.appendChild(track);
  let dist = 0;
  points.forEach((pt) => {
    dist += Number(pt.distance);
    const powerHistoryEntry = powerHistory.find((p) => p.time === pt.timestamp);
    const power = powerHistoryEntry?.powerAvg;
    const speed = powerHistoryEntry?.speed;
    const heartRateHistoryEntry = heartrateHistory.find(
      (h) => h.time === pt.timestamp
    );
    const heartRate = heartRateHistoryEntry?.heartrate;

    const trackPoint = xmlDoc.createElement("Trackpoint");
    track.appendChild(trackPoint);
    const time = xmlDoc.createElement("Time");
    const datetime = moment
      .unix(pt.timestamp)
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    time.appendChild(xmlDoc.createTextNode(datetime));
    trackPoint.appendChild(time);
    const position = xmlDoc.createElement("Position");
    trackPoint.appendChild(position);
    const latitudeDegrees = xmlDoc.createElement("LatitudeDegrees");
    latitudeDegrees.appendChild(xmlDoc.createTextNode(pt.lat + ""));
    position.appendChild(latitudeDegrees);
    const longitudeDegrees = xmlDoc.createElement("LongitudeDegrees");
    longitudeDegrees.appendChild(xmlDoc.createTextNode(pt.lon + ""));
    position.appendChild(longitudeDegrees);
    const altitudeMeters = xmlDoc.createElement("AltitudeMeters");
    altitudeMeters.appendChild(xmlDoc.createTextNode(pt.elevation + ""));
    trackPoint.appendChild(altitudeMeters);
    const distanceMeters = xmlDoc.createElement("DistanceMeters");
    distanceMeters.appendChild(xmlDoc.createTextNode(dist + ""));
    trackPoint.appendChild(distanceMeters);
    const heartRateBpm = xmlDoc.createElement("HeartRateBpm");
    trackPoint.appendChild(heartRateBpm);
    const value = xmlDoc.createElement("Value");
    value.appendChild(xmlDoc.createTextNode(heartRate + ""));
    heartRateBpm.appendChild(value);
    const extensions = xmlDoc.createElement("Extensions");
    trackPoint.appendChild(extensions);
    const tpX = xmlDoc.createElement("TPX");
    tpX.setAttribute(
      "xmlns",
      "http://www.garmin.com/xmlschemas/ActivityExtension/v2"
    );
    extensions.appendChild(tpX);
    const speedNode = xmlDoc.createElement("Speed");
    speedNode.appendChild(xmlDoc.createTextNode(speed + ""));
    tpX.appendChild(speedNode);
    const watts = xmlDoc.createElement("Watts");
    watts.appendChild(xmlDoc.createTextNode(power + ""));
    tpX.appendChild(watts);
  });

  const serializer = new XMLSerializer();
  const xmlHeader = '<?xml version="1.0" encoding="UTF-8" standalone="no" ?>';
  return xmlHeader + "\n" + serializer.serializeToString(xmlDoc);
};
