import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import reducers from '../Reducers';

const Store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default Store;
