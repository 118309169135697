import {
  CONNECTION_FAILED,
  RECEIVE_CADENCE,
  RECEIVE_CADENCE_DEBUG,
  RECEIVE_HEARTRATE,
  RECEIVE_POWER,
  RECEIVE_SPEED,
  receiveCadence,
  SENSOR_CONNECTED,
  SENSOR_TYPE_CADENCE,
  SENSOR_TYPE_FITNESS_MACHINE,
  SENSOR_TYPE_HEARTRATE,
  SENSOR_TYPE_POWER,
  SENSOR_TYPE_SPEED,
  SET_TIRE_CIRCUMFERENCE,
  SET_USER_FTP,
  SET_USER_MAX_HR,
  SET_USER_WEIGHT,
  START_CONNECTING,
} from "./Actions";
import { log } from "../../App";

export const initialState = {
  hasPower: process.env.NODE_ENV !== "production", // for debug reasons enable power always when we are not in prod
  hasCadence: false,
  hasHeartrate: false,
  hasFitnessMachine: false,
  hasSpeed: false,
  hasVirtualPower: false,
  powerConnecting: false,
  cadenceConnecting: false,
  heartrateConnecting: false,
  fitnessMachineConnecting: false,
  speedConnecting: false,
  powerConnectionError: false,
  cadenceConnectionError: false,
  heartrateConnectionError: false,
  fitnessMachineConnectionError: false,
  speedConnectionError: false,
  currentPower: 0,
  currentPowerPerKg: 0,
  powerHistory: [],
  currentCadence: 0,
  cadenceHistory: [],
  lastCadenceEventTime: undefined,
  lastCadenceRevolutions: undefined,
  lastWheelEventTime: undefined,
  lastWheelRevolutions: undefined,
  tireCircumference: 2105, // in milimeters
  currentHeartrate: 0,
  heartrateHistory: [],
  currentSpeed: 0,
  ridingDistance: 0,
  ridingElevation: 0,
  lastUpdate: undefined, // last bluetooth event received
  lastChange: undefined, // last power value over zero (player is active)
  timeElapsed: 0,
  kcal: 0,
  userFtp: 170,
  userMaxHr: 190,
  userWeight: 75,
  currentFtpZone: 1,
  currentHrZone: 1,
};

// x³ + ax² + bx + c = 0
const solve = (a, b, c) => {
  // => p = b-(a²/3), q=(2a³)/27-(a*b)/3+c, D=q²/4+p³/27
  // => v = z-a/3
  // z = cbrt(-q/2+sqrt(D)) + cbrt(-q/2-sqrt(D))
  console.log("a " + a);
  console.log("b " + b);
  console.log("c " + c);

  const p = b - Math.pow(a, 2) / 3;
  const q = (2 * Math.pow(a, 3)) / 27 - (a * b) / 3 + c;
  const D = Math.pow(q, 2) / 4 + Math.pow(p, 3) / 27;

  console.log("D " + D);

  if ((q * q) / 4 + (p * p * p) / 27 < 0) {
    // solve for casus irreducibilis -> we get three solutions and use the largest value:
    // https://en.wikipedia.org/wiki/Casus_irreducibilis#Trigonometric_solution_in_terms_of_real_quantities
    const t0 =
      2 *
      Math.sqrt(-p / 3) *
      Math.cos((1 / 3) * Math.acos(((3 * q) / (2 * p)) * Math.sqrt(-3 / p)));
    const t1 =
      2 *
      Math.sqrt(-p / 3) *
      Math.cos(
        (1 / 3) * Math.acos(((3 * q) / (2 * p)) * Math.sqrt(-3 / p)) -
          (2 * Math.PI) / 3
      );
    const t2 =
      2 *
      Math.sqrt(-p / 3) *
      Math.cos(
        (1 / 3) * Math.acos(((3 * q) / (2 * p)) * Math.sqrt(-3 / p)) -
          (4 * Math.PI) / 3
      );
    console.log("solve irreducibilis " + t0 + " / " + t1 + " / " + t2);
    return Math.max(t0, t1, t2);
  } else {
    const z1 =
      Math.cbrt(-q / 2 + Math.sqrt(D)) + Math.cbrt(-q / 2 - Math.sqrt(D));
    const v1 = z1 - a / 3;
    return v1;
  }
};

export const getFtpZone = (power, userFtp) => {
  // cycling weekly training zones
  // ftp zones / max hr zones
  // <56 / <60
  // 56-75 / 60-65
  // 76-90 / 65-75
  // 91-105 / 75-82
  // 106-120 / 82-89
  // 121+ / 89+

  if (power < userFtp * 0.56) {
    return 1;
  } else if (power < userFtp * 0.75) {
    return 2;
  } else if (power < userFtp * 0.9) {
    return 3;
  } else if (power < userFtp * 1.05) {
    return 4;
  } else if (power < userFtp * 1.2) {
    return 5;
  } else {
    return 6;
  }
};

export const getFtpZone12 = (power, userFtp) => {
  if (power < userFtp * 0.4) {
    return 1;
  } else if (power < userFtp * 0.56) {
    return 2;
  } else if (power < userFtp * 0.66) {
    return 3;
  } else if (power < userFtp * 0.75) {
    return 4;
  } else if (power < userFtp * 0.82) {
    return 5;
  } else if (power < userFtp * 0.9) {
    return 6;
  } else if (power < userFtp * 0.97) {
    return 7;
  } else if (power < userFtp * 1.05) {
    return 8;
  } else if (power < userFtp * 1.12) {
    return 9;
  } else if (power < userFtp * 1.2) {
    return 10;
  } else {
    return 11;
  }
};

const getHrZone = (heartrate, maxHeartrate) => {
  if (heartrate < maxHeartrate * 0.6) {
    return 1;
  } else if (heartrate < maxHeartrate * 0.65) {
    return 2;
  } else if (heartrate < maxHeartrate * 0.75) {
    return 3;
  } else if (heartrate < maxHeartrate * 0.82) {
    return 4;
  } else if (heartrate < maxHeartrate * 0.89) {
    return 5;
  } else {
    return 6;
  }
};

export default function BluetoothReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_POWER: {
      return processPowerEvent(state, action.power, action.slope);
    }

    case RECEIVE_CADENCE: {
      // event time format: 1/1024 s
      let cadence = 0;
      if (state.lastCadenceEventTime && state.lastCadenceRevolutions) {
        const timediff = action.eventTime - state.lastCadenceEventTime;
        const revolutions =
          action.totalRevolutions - state.lastCadenceRevolutions;
        const newCadence = Math.round((revolutions / (timediff / 1024)) * 60);
        if (!isNaN(newCadence)) {
          cadence = newCadence;
        }
      }
      // dont update when negative
      if (cadence < 0) {
        cadence = state.currentCadence;
      }
      return {
        ...state,
        lastCadenceEventTime: action.eventTime,
        lastCadenceRevolutions: action.totalRevolutions,
        currentCadence: cadence,
        hasCadence: true,
      };
    }

    case RECEIVE_SPEED: {
      if (state.hasPower) {
        console.warn("dont use speed, power is already in use");
        return { ...state };
      } else {
        let newSpeed = 0;
        if (state.lastWheelEventTime && state.lastWheelRevolutions) {
          const revolutions =
            action.totalRevolutions - state.lastWheelRevolutions;
          const timediff = action.eventTime - state.lastWheelEventTime; // in 1024th
          const distance = (revolutions * state.tireCircumference) / 1000;
          newSpeed = distance / (timediff / 1024);
          console.log("virtual speed " + newSpeed);
        }

        const virtualPower = calculateVirtualPower(newSpeed);
        if (!isNaN(virtualPower)) {
          const result = processPowerEvent(state, virtualPower);
          return {
            ...result,
            lastWheelEventTime: action.eventTime,
            lastWheelRevolutions: action.totalRevolutions,
            hasVirtualPower: true,
          };
        } else {
          return {
            ...state,
            lastWheelEventTime: action.eventTime,
            lastWheelRevolutions: action.totalRevolutions,
          };
        }
      }
    }

    case RECEIVE_CADENCE_DEBUG: {
      return {
        ...state,
        currentCadence: action.cadence,
        hasCadence: true,
      };
    }

    case RECEIVE_HEARTRATE: {
      const timestampMillis = Date.now();
      const timestamp = Math.floor(timestampMillis / 1000);

      const currentHrZone = getHrZone(action.heartrate, state.userMaxHr);

      const history = {};
      state.heartrateHistory.forEach((hh) => {
        history[hh.time] = hh;
      });
      history[timestamp] = {
        time: timestamp,
        heartrate: action.heartrate,
        zone: currentHrZone,
      };
      const heartrateHistory = [];
      Object.keys(history).forEach((k) => heartrateHistory.push(history[k]));

      return {
        ...state,
        currentHeartrate: action.heartrate,
        currentHrZone,
        hasHeartrate: true,
        heartrateHistory,
      };
    }

    case SENSOR_CONNECTED: {
      const { sensorType, status } = action;
      if (status === true) {
        console.log("sensor connected " + sensorType);
      } else {
        console.log("sensor disconnected " + sensorType);
      }
      if (sensorType === SENSOR_TYPE_POWER) {
        return { ...state, hasPower: status };
      } else if (sensorType === SENSOR_TYPE_CADENCE) {
        return { ...state, hasCadence: status };
      } else if (sensorType === SENSOR_TYPE_SPEED) {
        return { ...state, hasSpeed: status };
      } else if (sensorType === SENSOR_TYPE_HEARTRATE) {
        return { ...state, hasHeartrate: status };
      } else if (sensorType === SENSOR_TYPE_FITNESS_MACHINE) {
        return { ...state, hasFitnessMachine: status };
      } else {
        return { ...state };
      }
    }

    case START_CONNECTING: {
      if (action.sensorType === SENSOR_TYPE_POWER) {
        return { ...state, powerConnecting: true, powerConnectionError: false };
      } else if (action.sensorType === SENSOR_TYPE_CADENCE) {
        return {
          ...state,
          cadenceConnecting: true,
          cadenceConnectionError: false,
        };
      } else if (action.sensorType === SENSOR_TYPE_SPEED) {
        return { ...state, speedConnecting: true, speedConnectionError: false };
      } else if (action.sensorType === SENSOR_TYPE_HEARTRATE) {
        return {
          ...state,
          heartrateConnecting: true,
          heartrateConnectionError: false,
        };
      } else if (action.sensorType === SENSOR_TYPE_FITNESS_MACHINE) {
        return {
          ...state,
          fitnessMachineConnecting: true,
          fitnessMachineConnectionError: false,
        };
      } else {
        return { ...state };
      }
    }

    case CONNECTION_FAILED: {
      if (action.sensorType === SENSOR_TYPE_POWER) {
        return {
          ...state,
          powerConnecting: false,
          powerConnectionError: !action.reconnect,
        };
      } else if (action.sensorType === SENSOR_TYPE_CADENCE) {
        return {
          ...state,
          cadenceConnecting: false,
          cadenceConnectionError: !action.reconnect,
        };
      } else if (action.sensorType === SENSOR_TYPE_SPEED) {
        return { ...state, speedConnecting: false, speedConnectionError: true };
      } else if (action.sensorType === SENSOR_TYPE_HEARTRATE) {
        return {
          ...state,
          heartrateConnecting: false,
          heartrateConnectionError: !action.reconnect,
        };
      } else if (action.sensorType === SENSOR_TYPE_FITNESS_MACHINE) {
        return {
          ...state,
          fitnessMachineConnecting: false,
          fitnessMachineConnectionError: !action.reconnect,
        };
      } else {
        return { ...state };
      }
    }

    case SET_USER_FTP: {
      return { ...state, userFtp: action.userFtp };
    }

    case SET_USER_MAX_HR: {
      return { ...state, userMaxHr: action.userMaxHr };
    }

    case SET_USER_WEIGHT: {
      return { ...state, userWeight: action.userWeight };
    }

    case SET_TIRE_CIRCUMFERENCE: {
      return { ...state, tireCircumference: Number(action.circumference) };
    }

    default:
      return state;
  }
}

const calculateVirtualPower = (v) => {
  // P = (Fg + Fr + Fa) * v / (1 - loss)
  //
  // where:
  //
  //     P is your power,
  //   Fg is the resisting force due to gravity,
  //   Fr is the rolling resistance force,
  //   Fa is the aerodynamic drag,
  //   v is your speed in m/s,
  //   loss is the percentage loss in power (1.5% + 3%)

  // Fa = Ac * v² // without wind
  // Ac = (0.5 * Cd * A * ρ) = constant

  let g = 9.80655; // gravity
  let slope = 0; // in percent
  let Crr = 0.005; // rolling resistance coefficient. (asphalt + slick tires = 0.0050)
  let h = 200; // height above sea level
  let Mr = 75; // mass of rider (kg)
  let Mb = 5; // mass of bike (kg)
  let m = Mr + Mb; // total mass

  let Ac = 0.5 * 0.324 * 1.225 * Math.exp(-0.00011856 * h);
  let Fg = g * Math.sin(Math.atan(slope)) * m;
  let Fr = g * Math.cos(Math.atan(slope)) * m * Crr; // Math.atan == arctan
  let Fa = Ac * Math.pow(v, 2);

  let loss = 0.045; // 1,5% general + 3% (good chain)

  let P = Math.round((Fg + Fr + Fa) * (v / (1 - loss)));

  log("Speed: " + v + " m/s / " + v * 3.6 + " km/h");
  log("Virtual Power is: " + P + " Watts");

  return isNaN(P) ? 0 : P;
};

// power in watts, slope in percent
const processPowerEvent = (state, power, slopeInPercent = 0) => {
  const timestampMillis = Date.now();
  const timestamp = Math.floor(timestampMillis / 1000);
  const timeElapsed = state.lastUpdate ? timestampMillis - state.lastUpdate : 0;
  console.log("time elapsed: " + timeElapsed + "ms");

  // https://www.omnicalculator.com/sports/cycling-wattage#what-is-the-cycling-wattage
  // https://www.gribble.org/cycling/power_v_speed.html
  // http://www.kreuzotter.de/deutsch/speed.htm
  // https://betterbicycles.org/bicycle-power-calculations/

  // The cycling wattage formula that we use looks like this:
  //
  // P = (Fg + Fr + Fa) * v / (1 - loss)
  //
  // where:
  //
  //   P is your power,
  //   Fg is the resisting force due to gravity,
  //   Fr is the rolling resistance force,
  //   Fa is the aerodynamic drag,
  //   v is your speed in m/s,
  //   loss is the percentage loss in power (1.5% + 3%)

  // Speed is =>
  // P = (Fg + Fr + Fa) * (v / (1 - loss)) | / (F...)
  // P / (Fg + Fr + Fa) = v / (1 - loss) | * (1 - loss)
  // v = P / (Fg + Fr + Fa) * (1 - loss)

  // v = ( P * (1 - loss) / (Fg + Fr) ) + (P * (1 - loss) / Fa)

  // P = (Fg + Fr + Fa) * v / (1 - loss)
  // P = (Fg + Fr + Fa) * v / (1 - loss)

  // WITHOUT WIND (but with aerodynamic drag)
  // Fa = Ac * v² // without wind
  // Ac = (0.5 * Cd * A * ρ) = constant

  // P = (Fg + Fr + Fa) * (v / (1 - loss))
  // P = (Fg + Fr + Ac * v²) * (v / (1 - loss))
  // P = (Fg + Fr + Ac * v²) * (v / (1 - loss))
  //     = Fg * (v/(1-loss)) + Fr * (v/(1-loss)) + (Ac*v²)*(v/(1-loss))
  //   = Fg * (v/(1-loss)) + Fr * (v/(1-loss))  + (Ac*v³/(1-loss)
  // P*(1-loss)  = Fg * v + Fr * v + (Ac*v³)
  // P*(1-loss)/Ac  = Fg * v * (1/Ac) + Fr * v * (1/Ac) + v³
  //  0 = Fg * v * (1/Ac) + Fr * v * (1/Ac) + v³ - P*(1-loss)/Ac
  // v³+ v * (1/Ac)*(Fg+Fr) - P*(1-loss) = 0

  // v³ + av² + bv + c = 0
  // a = 0, b = (1/Ac)*(Fg+Fr), c = -P*(1-loss)/Ac

  // Fg = g * sin(arctan(slope)) * (M + m)
  // => 0 for no slope
  // where:
  //
  //     Fg is the resisting force due to gravity,
  //   g is the gravitational acceleration, equal to 9.80655 m/s²,
  // slope is the slope of the hill, expressed as a percentage (positive for going uphill and negative for going downhill),
  // M is your weight in kg,
  //   m is the weight of your bicycle and any extra gear, also in kg.

  // Fr = g * cos(arctan(slope)) * (M + m) * Crr
  // => Fr = g * (M+m) * Crr for no slope

  // where:
  //
  //     Fr is the rolling resistance,
  //   g is the gravitational acceleration, equal to 9.80655 m/s²,
  // slope is the slope of the hill, expressed as a percentage (positive for going uphill and negative for going downhill),
  // M is your weight in kg,
  //   m is the weight of your bicycle and any extra gear in kg,
  //   Crr is the rolling resistance coefficient. (asphalt + slick tires = 0.0050)

  //       Fa = 0.5 * Cd * A * ρ * (v + w)²
  //
  // where:
  //
  //     Fa is the aerodynamic drag,
  //         Cd is the drag coefficient,
  //         A is your frontal area,
  //         ρ is the air density,
  //         v is your speed,
  //         w is the wind speed (positive for head wind and negative for tail wind).

  // Cd * A = 0.324 (position hoods)
  // ρ = 1.225 * exp(-0.00011856 * h) with h = meters above sea level

  let powerAvg = !isNaN(power) ? power : 0;
  console.log(state.powerHistory);
  if (state.powerHistory && state.powerHistory.length > 1) {
    powerAvg = Math.round(
      (Number(state.powerHistory[state.powerHistory.length - 1].power) +
        Number(state.powerHistory[state.powerHistory.length - 2].power) +
        Number(powerAvg)) /
        3
    );
    console.log("average power " + powerAvg);
  }

  let P = powerAvg; // Power
  let g = 9.80655; // gravity
  let slope = slopeInPercent ?? 0; // in percent
  let Crr = 0.005; // rolling resistance coefficient. (asphalt + slick tires = 0.0050)
  let h = 200; // height above sea level
  let Mr = 75; // mass of rider (kg)
  let Mb = 5; // mass of bike (kg)
  let m = Mr + Mb; // total mass

  let Ac = 0.5 * 0.324 * 1.225 * Math.exp(-0.00011856 * h);
  let Fg = g * Math.sin(Math.atan(slope / 100)) * m;
  let Fr = g * Math.cos(Math.atan(slope / 100)) * m * Crr; // Math.atan == arctan
  let loss = 0.045; // 1,5% general + 3% (good chain)

  let a = 0;
  let b = (1 / Ac) * (Fg + Fr);
  let c = (-P * (1 - loss)) / Ac;

  let v = solve(a, b, c);
  console.log("calculated speed " + v + " / slope " + slope);

  // todo: add max speed to avoid ultra high speeds when going downhill?

  const distanceGain = ((timeElapsed / 1000) * (state.currentSpeed + v)) / 2;
  const ridingDistance = state.ridingDistance + distanceGain;
  const elevationGain = (distanceGain * slopeInPercent) / 100;
  console.log("elevation gained: " + elevationGain);
  let ridingElevation = state.ridingElevation;
  if (elevationGain > 0) {
    ridingElevation += elevationGain;
  }

  // calculate calories
  // Energy (Joules) = Power (Watts) * Time (Seconds)
  // 1 Joule = 0.238902957619 calorie
  // Human efficiency ~24% -> 1 Joule measured = 1 calorie burnt
  const energyDelta =
    ((timeElapsed / 1000) * (state.currentPower + powerAvg)) / 2;

  const history = {};
  state.powerHistory.forEach((ph) => {
    history[ph.time] = ph;
  });

  console.log("user ftp: " + state.userFtp);
  const zone = getFtpZone(powerAvg, state.userFtp);

  history[timestamp] = {
    time: timestamp,
    power: power,
    powerAvg: powerAvg,
    speed: v,
    zone,
  };
  const powerHistory = [];
  Object.keys(history).forEach((k) => powerHistory.push(history[k]));

  return {
    ...state,
    currentPower: powerAvg,
    currentPowerPerKg: Math.round((powerAvg / state.userWeight) * 10) / 10,
    currentSpeed: v,
    powerHistory,
    lastUpdate: timestampMillis,
    lastChange: powerAvg > 0 ? timestampMillis : state.lastChange,
    ridingDistance,
    timeElapsed: state.timeElapsed + timeElapsed / 1000,
    kcal: state.kcal + energyDelta / 1000,
    currentFtpZone: zone,
    ridingElevation,
  };
};
