import { setFitnessMachineTargetPower } from "../Bluetooth/Actions";

export const SET_WORKOUT = "SET_WORKOUT";
export const START_WORKOUT = "START_WORKOUT";
export const SET_CURRENT_TIME = "SET_CURRENT_TIME";
export const STOP_WORKOUT = "STOP_WORKOUT";

let interval = undefined;

export const startWorkout = () => (dispatch, getState) => {
  let timer = getState().Workout.workoutTime;
  const maxTime = getState().Workout.workoutDuration;
  console.log("max time: " + maxTime);
  if (interval) {
    clearInterval(interval);
  }
  interval = setInterval(() => {
    timer++;
    if (timer > maxTime) {
      clearInterval(interval);
      dispatch({
        type: STOP_WORKOUT,
      });
    } else {
      dispatch(setCurrentTime(timer));
    }
  }, 1000);
  dispatch({
    type: START_WORKOUT,
  });
};

export const stopWorkout = () => (dispatch) => {
  console.log("stop");
  clearInterval(interval);
  dispatch({
    type: STOP_WORKOUT,
  });
};

export const setCurrentTime = (time) => async (dispatch, getState) => {
  dispatch({
    type: SET_CURRENT_TIME,
    time,
  });
  const activeFtp = getState().Workout.activeFtp;
  const ftp = getState().Player.ftp;
  const targetPower = Math.round(activeFtp * ftp);

  if (getState().Bluetooth.hasFitnessMachine) {
    dispatch(setFitnessMachineTargetPower(targetPower));
  }
};

export const loadWorkout = (xmlString) => (dispatch) => {
  try {
    let index = 0;
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const workout = [];
    const workoutXml = xmlDoc.getElementsByTagName("workout");
    //console.log(workoutXml);
    let workoutDuration = 0;
    workoutXml[0].childNodes.forEach((child) => {
      let texts = [];
      if (child.childNodes) {
        console.log("has children");
        child.childNodes.forEach((textChild) => {
          if (textChild.nodeName === "textevent") {
            const message = textChild.getAttribute("message");
            const time = textChild.getAttribute("timeoffset");
            let duration = textChild.getAttribute("duration");
            if (!duration) {
              duration = 5;
            }
            texts.push({ message, time, duration });
          }
        });
      }

      switch (child.nodeName) {
        case "Warmup":
        case "Cooldown":
          workoutDuration += Math.round(child.getAttribute("Duration"));
          workout.push({
            index: index++,
            type: child.nodeName,
            duration: Math.round(child.getAttribute("Duration")),
            powerLow: Math.round(child.getAttribute("PowerLow") * 100) / 100,
            powerHigh: Math.round(child.getAttribute("PowerHigh") * 100) / 100,
            texts,
          });
          break;
        case "IntervalsT":
          const repeat = Number(child.getAttribute("Repeat"));
          workoutDuration +=
            repeat *
            (Math.round(child.getAttribute("OnDuration")) +
              Math.round(child.getAttribute("OffDuration")));
          for (let i = 0; i < repeat; i++) {
            workout.push({
              index: index++,
              type: "SteadyState",
              duration: Math.round(child.getAttribute("OnDuration")),
              power: Math.round(child.getAttribute("OnPower") * 100) / 100,
              texts,
            });
            workout.push({
              index: index++,
              type: "SteadyState",
              duration: Math.round(child.getAttribute("OffDuration")),
              power: Math.round(child.getAttribute("OffPower") * 100) / 100,
              texts,
            });
          }
          break;
        case "SteadyState":
          workoutDuration += Math.round(child.getAttribute("Duration"));
          workout.push({
            index: index++,
            type: child.nodeName,
            duration: Math.round(child.getAttribute("Duration")),
            power: Math.round(child.getAttribute("Power") * 100) / 100,
            texts,
          });
          break;
      }
    });
    console.log(workout);

    dispatch({ type: SET_WORKOUT, workout, duration: workoutDuration });
  } catch (e) {
    console.log("parse error");
  }
};
